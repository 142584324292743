import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { Action, ActionCreator, Dispatch } from 'redux';
import { setFieldErrors } from '../redux/actions/fieldErrors';
import { addFormError } from '../redux/actions/fieldErrors';
import { setRedirectUrl } from '../redux/actions/redirects';
import { APIError } from '../types/APIError';
import { FieldErrorsObj } from '../types/shared/fieldErrors';
import { postLogout } from '../redux/actions/logout';
import qs from 'qs';

const getConfig = <TParams>(params?: TParams) => {
    const config: AxiosRequestConfig = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
            'id-token': localStorage.getItem('idToken') || '',
        },
    };

    if (params) {
        config.params = params;
        config.paramsSerializer = p => {
            return qs.stringify(p);
        };
    }

    return config;
};

const getMediaConfig = () => ({
    headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        'id-token': localStorage.getItem('idToken') || '',
        'content-type': 'multipart/form-data',
    },
});

let API_URL = '';
let OAUTH_ACCESS_TOKEN_URL = '';

export const initApi = (apiUrl: string, oauthAccessTokenUrl: string) => {
    API_URL = apiUrl;
    OAUTH_ACCESS_TOKEN_URL = oauthAccessTokenUrl;
};
const get = async <TResponseBody, TParams = void>(
    url: string,
    params?: TParams,
): Promise<AxiosResponse<TResponseBody>> => {
    await handleRefreshTokens();
    return axios.get<TResponseBody>(`${API_URL}/${url}`, getConfig(params));
};
const post = async <TRequestBody, TResponseBody>(url: string, data: TRequestBody) => {
    await handleRefreshTokens();
    return axios.post<TResponseBody>(`${API_URL}/${url}`, data, getConfig());
};
const postMedia = <TRequestBody, TResponseBody>(url: string, data: TRequestBody) => {
    return axios.post<TResponseBody>(`${API_URL}/${url}`, data, getMediaConfig());
};
const put = async <TRequestBody, TResponseBody>(url: string, data: TRequestBody) => {
    await handleRefreshTokens();
    return axios.put<TResponseBody>(`${API_URL}/${url}`, data, getConfig());
};
const patch = async <TRequestBody, TResponseBody>(url: string, data: TRequestBody) => {
    await handleRefreshTokens();
    return axios.patch<TResponseBody>(`${API_URL}/${url}`, data, getConfig());
};
const del = async <TResponseBody>(url: string) => {
    await handleRefreshTokens();
    return axios.delete<TResponseBody>(`${API_URL}/${url}`, getConfig());
};

export const api = {
    get,
    post,
    postMedia,
    put,
    patch,
    delete: del,
};

export const handleApiErrors = <A extends Action>(
    dispatch: Dispatch,
    failureAction: ActionCreator<A>,
    err: APIError,
) => {
    const { response, message } = err;

    if (response && response.status === 400) {
        if (typeof response.data === 'string') {
            dispatch(addFormError(response.data));
        } else {
            const errors: FieldErrorsObj = {};
            Object.values(response.data).forEach(({ msg, param }) => (errors[param] = msg));
            dispatch(setFieldErrors(errors));
        }

        return dispatch(failureAction(null));
    }
    if (response && response.status === 401) {
        postLogout().finally(() => {
            dispatch(failureAction('Unauthorized'));
            dispatch(
                addFormError(
                    'Unauthorised, please login again (you may not have permissions to access this site)',
                ),
            );
            return dispatch(setRedirectUrl('/auth/login'));
        });
    }

    return dispatch(failureAction(message));
};

const handleRefreshTokens = async () => {
    try {
        const jwt = localStorage.getItem('jwt');
        const refreshToken = localStorage.getItem('refreshToken');

        if (!refreshToken || !jwt) {
            return;
        }
        const decoded = jwtDecode<JwtPayload>(jwt);
        const now = new Date().getTime() / 1000;
        const { exp = 0 } = decoded;
        // if token expires within 5 minutes, refresh
        const minutesUntilExpiration = (exp - now) / 60;
        if (minutesUntilExpiration <= 2) {
            const { data } = await axios.post<RefreshTokenRequest, AxiosResponse<LoginResponse>>(
                `${API_URL}/oauth/refresh-token`,
                {
                    grant_type: 'refresh_token',
                    refreshToken,
                },
                getConfig(),
            );
            const { access_token, id_token, refresh_token } = data;
            localStorage.setItem('jwt', access_token);
            localStorage.setItem('idToken', id_token);
            localStorage.setItem('refreshToken', refresh_token);
        }
    } catch {
        // ignore err, dealt with in handleApiErrors
    }
};

interface RefreshTokenRequest {
    refreshToken: string;
    expiredToken: string;
}

export interface LoginResponse {
    access_token: string;
    id_token: string;
    refresh_token: string;
}

export interface ErrorLogRequest {
    message?: string;
    stackTrace?: string;
    appVersion: string;
    device: string;
    deviceOS: string;
    deviceRAM: number | null;
}
