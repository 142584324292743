import ConfirmModal from '@components/modal/ConfirmModal';
import { User } from 'src/types/shared/User';
import useDeleteUser from '../hooks/useDeleteUser';

const DeleteUserModal: React.FC<DeleteUserModalProps> = ({ user, closeModal }) => {
    const { error, isDeleting, deleteAUser } = useDeleteUser(user.id, closeModal);

    return (
        <ConfirmModal
            title="Delete user"
            description={`Are you sure you want to delete '${user.firstName} ${user.lastName}'?`}
            closeModal={() => closeModal()}
            handleSubmit={deleteAUser}
            isPosting={isDeleting}
            error={error}
        />
    );
};

interface DeleteUserModalProps {
    user: User;
    closeModal: () => void;
}

export default DeleteUserModal;
