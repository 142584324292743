import React, { ReactNode } from 'react';

const MessageStrip: React.FC<MessageStripProps> = ({ children }) => (
    <div className="message-strip">{children}</div>
);

export interface MessageStripProps {
    children: ReactNode;
}

export default MessageStrip;
