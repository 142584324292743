import React, { ReactNode } from 'react';

const Message: React.FC<MessageProps> = ({ children, extraStyle, extraClasses = '' }) => (
    <p className={`no-message ${extraClasses}`} style={extraStyle}>
        {children}
    </p>
);

interface MessageProps {
    children: ReactNode;
    extraClasses?: string;
    extraStyle?: React.CSSProperties;
}

export default Message;
