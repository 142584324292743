import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import dayjs from 'dayjs';

import { selectUsers } from '@selectors/users';

import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import Table from 'lib/src/components/table/Table';

import { Entity, EntityUpdateLogs } from 'src/types/shared/Entity';

import { DATE_TIME_FORMAT } from 'lib/src/constants/dateFormats';

const EntityEditHistory: React.FC<EntityEditHistoryProps> = ({ entity }) => {
    const users = useSelector(selectUsers);

    const tableRows = useMemo(() => {
        const dates = entity.updateLogs.map(log => log.createdOn);
        const uniqueDates = [...new Set(dates)];
        const rows: EditHistoryRow[] = [];

        uniqueDates.forEach((date, index) => {
            const logsForDate = entity.updateLogs.filter(log => log.createdOn === date);
            const userID = logsForDate[0].createdByUserID;
            const user = users[userID];

            rows.push({
                id: index,
                admin: user ? `${user.firstName} ${user.lastName}` : 'Unknown',
                date,
                actions: logsForDate,
            });
        });

        return rows.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    }, [entity, users]);

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [entity.updateLogs]);

    return (
        <ContentBlock>
            <p className="page-subtitle">Edit History</p>

            <Table columns={columns} rows={tableRows} hasTooltips />
        </ContentBlock>
    );
};

const columns = [
    {
        key: 1,
        heading: 'Admin',
        getValue: (row: EditHistoryRow) => row.admin,
    },
    {
        key: 2,
        heading: 'Date / Time',
        getValue: (row: EditHistoryRow) => dayjs(row.date).format(DATE_TIME_FORMAT),
    },
    {
        key: 3,
        heading: 'Action(s)',
        getValue: (row: EditHistoryRow) => (
            <span>
                {`${row.actions.length} Edit${row.actions.length > 1 ? '(s)' : ''} Made`}
                <i
                    className="fa fa-info-circle"
                    style={{ marginLeft: 5 }}
                    data-tip={`${row.actions
                        .map(
                            action =>
                                `<p>'${action.fieldName}' changed from '${action.oldValue}' to '${action.newValue}'</p>`,
                        )
                        .join('')}`}
                    data-html
                ></i>
            </span>
        ),
    },
];

interface EntityEditHistoryProps {
    entity: Entity;
}

interface EditHistoryRow {
    id: number;
    admin: string;
    date: Date;
    actions: EntityUpdateLogs[];
}

export default EntityEditHistory;
