import React from 'react';
import {
    ComposedChart as Chart,
    Bar,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ReferenceLine,
    ResponsiveContainer,
    Brush,
} from 'recharts';

const ComposedChart: React.FC<ComposedChartProps> = ({
    data = [],
    bars = [],
    lines = [],
    referenceLines = [],
    layout = 'horizontal',
    showGrid = false,
    showTooltip = false,
    showLegend = false,
    XDomain = ['dataMin', 'dataMax'],
    YDomain = ['dataMin', 'dataMax'],
    XAxisTicks = true,
    YAxisTicks = true,
    ninetyFiveReferenceLabel = false,
    scroll = false,
    radius = [0, 0, 0, 0],
    containerClasses = '',
    minHeight = '100px',
    YUnit,
    XUnit,
    XWidth = 1,
    XHeight,
    YWidth = 1,
    YHeight,
}) => {
    const isHorizontal = layout === 'horizontal';

    return (
        <ResponsiveContainer minHeight={minHeight} className={containerClasses}>
            <Chart data={data} layout={layout}>
                {showGrid && <CartesianGrid strokeDasharray="3 3" />}
                <XAxis
                    type={isHorizontal ? 'category' : 'number'}
                    dataKey={isHorizontal ? 'name' : undefined}
                    tick={XAxisTicks}
                    width={XWidth}
                    height={XHeight}
                    domain={XDomain}
                    unit={XUnit}
                    tickFormatter={isHorizontal ? undefined : (tick) => tick + '%'}
                />
                <YAxis
                    type={isHorizontal ? 'number' : 'category'}
                    dataKey={isHorizontal ? undefined : 'name'}
                    domain={YDomain}
                    tick={YAxisTicks}
                    tickFormatter={isHorizontal ? (tick) => tick + '%' : undefined}
                    width={YWidth}
                    height={YHeight}
                    unit={YUnit}
                />
                {scroll && data.length > 2 && <Brush dataKey="name" height={30} stroke="#8884d8" />}
                {showTooltip && <Tooltip cursor={{ opacity: 0.1, fill: '#004876' }} />}
                {showLegend && <Legend />}
                {referenceLines.map(line => (
                    <ReferenceLine
                        key={line.value}
                        x={isHorizontal ? undefined : line.value}
                        y={isHorizontal ? line.value : undefined}
                        stroke={line.stroke}
                        strokeWidth={2}
                        label={
                            ninetyFiveReferenceLabel
                                ? {
                                      position: 'left',
                                      value: '95%',
                                      fill: 'green',
                                  }
                                : false
                        }
                    />
                ))}
                {bars.map(bar => (
                    <Bar key={bar.dataKey} dataKey={bar.dataKey} fill={bar.fill} radius={radius}/>
                ))}
                {lines.map(line => (
                    <Line
                        key={line.dataKey}
                        type="monotone"
                        dataKey={line.dataKey}
                        stroke={line.stroke}
                        strokeWidth={2}
                        dot={false}
                        activeDot={false}
                    />
                ))}
            </Chart>
        </ResponsiveContainer>
    );
};

interface ComposedChartProps {
    data: any[];
    bars: BarProps[];
    lines?: LineProps[];
    referenceLines: ReferenceLineProps[];
    layout?: 'horizontal' | 'vertical';
    showGrid?: boolean;
    showTooltip?: boolean;
    showLegend?: boolean;
    XDomain?: [number | string, number | string];
    YDomain?: [number | string, number | string];
    XAxisTicks?: boolean;
    YAxisTicks?: boolean;
    ninetyFiveReferenceLabel?: boolean;
    scroll?: boolean;
    radius?: [number, number, number, number];
    containerClasses?: string;
    minHeight?: string | number;
    YUnit?: string;
    XUnit?: string;
    XWidth?: number;
    XHeight?: number;
    YWidth?: number;
    YHeight?: number;
}

export interface BarProps {
    dataKey: string;
    fill: string;
}

export interface LineProps {
    dataKey: string;
    stroke: string;
}

export interface ReferenceLineProps {
    value: number;
    stroke: string;
}

export default ComposedChart;
