import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { Entity } from 'src/types/shared/Entity';

export const fetchEntitiesRequest = createAction('fetchEntitiesRequest');
export const fetchEntitiesSuccess = createAction<Entity[]>('fetchEntitiesSuccess');
export const fetchEntitiesFailure = createAction('fetchEntitiesFailure');

export const fetchEntities =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchEntitiesRequest());
        try {
            const { data } = await api.get<Entity[]>('entities');
            dispatch(fetchEntitiesSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchEntitiesFailure, e as APIError);
        }
    };
