import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { User } from 'src/types/shared/User';

export const fetchCurrentUserRequest = createAction('fetchCurrentUserRequest');
export const fetchCurrentUserSuccess = createAction<User>('fetchCurrentUserSuccess');
export const fetchCurrentUserFailure = createAction('fetchCurrentUserFailure');

export const fetchCurrentUser =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchCurrentUserRequest());
        try {
            const { data } = await api.get<User>('current-user');

            dispatch(fetchCurrentUserSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchCurrentUserFailure, e as APIError);
        }
    };
