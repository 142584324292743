import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
    searchLeadersFailure,
    searchLeadersRequest,
    searchLeadersSuccess,
    compareLeadersRequest,
    compareLeadersSuccess,
    compareLeadersFailure,
} from '@actions/leaders';
import { Leader } from 'src/types/shared/LeaderSearch';
import { AnchorResult, ComparisonResponse, ComparisonResult } from 'src/types/shared/Comparison';

interface LeadersState {
    isPosting: boolean;
    postError: string | null;
    leaders: { [key: number]: Leader };
    isFetchingComparison: boolean;
    fetchComparisonError: string | null;
    anchorResult: AnchorResult | null;
    comparisonResults: ComparisonResult[] | null;
    leaderResult: AnchorResult | null;
}

const initialState: LeadersState = {
    isPosting: false,
    postError: null,
    leaders: {},
    isFetchingComparison: false,
    fetchComparisonError: null,
    anchorResult: null,
    comparisonResults: null,
    leaderResult: null,
};

export default createReducer(initialState, {
    [searchLeadersRequest.type]: handleSearchLeadersRequest,
    [searchLeadersSuccess.type]: handleSearchLeadersLeadersSuccess,
    [searchLeadersFailure.type]: handleSearchLeadersError,
    [compareLeadersRequest.type]: handleCompareLeadersRequest,
    [compareLeadersSuccess.type]: handleCompareLeadersSuccess,
    [compareLeadersFailure.type]: handleCompareLeadersError,
});

function handleSearchLeadersRequest(state: LeadersState) {
    state.isPosting = true;
    state.postError = null;
}

function handleSearchLeadersError(state: LeadersState, action: PayloadAction<string>) {
    state.postError = action.payload;
    state.isPosting = false;
}

function handleSearchLeadersLeadersSuccess(state: LeadersState, action: PayloadAction<Leader[]>) {
    state.isPosting = false;
    state.leaders = action.payload;
}

function handleCompareLeadersRequest(state: LeadersState) {
    state.isFetchingComparison = true;
    state.fetchComparisonError = null;
    state.anchorResult = null;
    state.comparisonResults = null;
    state.anchorResult = null;
}

function handleCompareLeadersError(state: LeadersState, action: PayloadAction<string>) {
    state.fetchComparisonError = action.payload;
    state.isFetchingComparison = false;
}

function handleCompareLeadersSuccess(
    state: LeadersState,
    action: PayloadAction<ComparisonResponse>,
) {
    state.isFetchingComparison = false;
    state.anchorResult = action.payload.anchorResults;
    state.comparisonResults = action.payload.comparisonResults;
    state.leaderResult = action.payload.leaderResults;
}
