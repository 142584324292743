import React from 'react';

import { Entity } from 'src/types/shared/Entity';
import { User } from 'src/types/shared/User';
import Table from 'lib/src/components/table/Table';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import LinkButton from 'lib/src/components/button/LinkButton';
import LatestPeerAssessment from './LatestPeerAssessment';
import LatestSelfAssessment from './LatestSelfAssessment';
import useFetchTopicsBehaviors from '../hooks/useFetchTopicsBehaviors';

const EntityLeaders: React.FC<EntityLeadersProps> = ({ entity, isAdmin }) => {
    const { topicsBehaviors } = useFetchTopicsBehaviors();

    const columns = [
        {
            key: 1,
            heading: 'UUID',
            getValue: (row: User) => row.uuid,
        },
        {
            key: 2,
            heading: 'First name',
            getValue: (row: User) => row.firstName,
        },
        {
            key: 3,
            heading: 'Last name',
            getValue: (row: User) => row.lastName,
        },
        {
            key: 4,
            heading: 'Latest self assessment',
            getValue: (row: User) => (
                <LatestSelfAssessment
                    userID={row.id}
                    entityID={entity.id}
                    topicsBehaviors={topicsBehaviors}
                />
            ),
        },
        {
            key: 5,
            heading: 'Latest peer assessment',
            getValue: (row: User) => (
                <LatestPeerAssessment
                    userID={row.id}
                    entityID={entity.id}
                    topicsBehaviors={topicsBehaviors}
                />
            ),
        },
        {
            key: 6,
            heading: '',
            getValue: (row: User) =>
                isAdmin ? (
                    <ButtonRow alignment="right">
                        <LinkButton href={`/users/${row.id}`}>View</LinkButton>
                    </ButtonRow>
                ) : null,
        },
    ];

    return <Table columns={columns} rows={entity.leaders} />;
};

interface EntityLeadersProps {
    entity: Entity;
    isAdmin: boolean;
}

export default EntityLeaders;
