import useFetchTopicsBehaviors from '@pages/entities/entity/hooks/useFetchTopicsBehaviors';
import useJobRolesComparisonFetch from './hooks/useJobRolesComparisonFetch';

import CreateHeader from '@components/layout/createHeader/CreateHeader';
import Meta from 'lib/src/components/meta/Meta';
import Title from 'lib/src/components/typography/Title';
import DataCheck from 'lib/src/components/layout/dataCheck/DataCheck';
import ComparisonChart from '@components/compare/ComparisonChart';

const JobRolesComparison: React.FC = () => {
    const {
        topicsBehaviors,
        isFetching: isFetchingTopicsBehaviors,
        fetchError: topicBehaviorsError,
    } = useFetchTopicsBehaviors();
    const {
        anchorJobRole,
        comparisonJobRoles,
        isFetching: isFetchingComparison,
        error: comparisonError,
    } = useJobRolesComparisonFetch();

    const title = `Job Roles Comparison${
        anchorJobRole ? ` for ${anchorJobRole.friendlyName}` : ''
    }`;

    return (
        <>
            <Meta title={title} />

            <CreateHeader>
                <Title>{title}</Title>
            </CreateHeader>

            <DataCheck
                isEmpty={!topicsBehaviors || !anchorJobRole || !comparisonJobRoles}
                isLoading={isFetchingTopicsBehaviors || isFetchingComparison}
                error={topicBehaviorsError || comparisonError}
            >
                {topicsBehaviors && anchorJobRole && comparisonJobRoles && (
                    <ComparisonChart
                        topicsBehaviours={topicsBehaviors}
                        anchor={anchorJobRole}
                        comparison={comparisonJobRoles}
                        leader={null}
                    />
                )}
            </DataCheck>
        </>
    );
};

export default JobRolesComparison;
