import { useState } from 'react';
import { useSelector } from 'react-redux';

import Table from 'lib/src/components/table/Table';
import { User } from 'src/types/shared/User';
import { userRoleNames } from 'lib/src/shared/enums/userEnums';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import LinkButton from 'lib/src/components/button/LinkButton';
import { selectUsersError } from '@selectors/users';
import useFetchEntities from '@pages/entities/entities/hooks/useFetchEntities';
import ActionButton from 'lib/src/components/button/ActionButton';
import DeleteUserModal from './modals/DeleteUserModal';
import { useHistory } from 'react-router-dom';

const UsersTable: React.FC<UsersTableProps> = ({ users, isFetching }) => {
    const { entitiesSimpleArr } = useFetchEntities();
    const fetchError = useSelector(selectUsersError);
    const [showDeleteModal, setShowDeleteModal] = useState<User | null>(null);
    const activeUsers = Object.values(users).filter(user => user.isDeleted === false);
    const history = useHistory();

    const handleNavigateToUser = (user: User) => {
        history.push(`/users/${user.id}`);
    };

    const columns = [
        {
            key: 1,
            heading: 'UUID',
            getValue: (row: User) => `${row.uuid}`,
            getSort: (a: User, b: User) => a.uuid.localeCompare(b.uuid),
            searchable: true,
            onClick: (row: User) => handleNavigateToUser(row),
        },
        {
            key: 2,
            heading: 'First Name',
            getValue: (row: User) => `${row.firstName}`,
            getSort: (a: User, b: User) => a.firstName.localeCompare(b.firstName),
            searchable: true,
            onClick: (row: User) => handleNavigateToUser(row),
        },
        {
            key: 3,
            heading: 'Last Name',
            getValue: (row: User) => `${row.lastName}`,
            getSort: (a: User, b: User) => a.lastName.localeCompare(b.lastName),
            searchable: true,
            onClick: (row: User) => handleNavigateToUser(row),
        },
        {
            key: 4,
            heading: 'Type',
            getValue: (row: User) => userRoleNames[row.role],
            searchable: true,
            onClick: (row: User) => handleNavigateToUser(row),
        },

        {
            key: 5,
            heading: 'Attached To Entity',
            getValue: (row: User) =>
                row.entityID
                    ? entitiesSimpleArr.find(entity => entity.id === row.entityID)?.name
                    : '-',
            searchable: true,
            onClick: (row: User) => handleNavigateToUser(row),
        },
        {
            key: 6,
            heading: '',
            getValue: (row: User) => (
                <ButtonRow alignment="right">
                    <LinkButton href={`/users/${row.id}`}>View</LinkButton>
                    <ActionButton source="negative" onClick={() => setShowDeleteModal(row)}>
                        Delete
                    </ActionButton>
                </ButtonRow>
            ),
        },
    ];

    return (
        <>
            <Table
                columns={columns}
                rows={activeUsers}
                isLoading={isFetching}
                fetchError={fetchError}
            />
            {showDeleteModal && (
                <DeleteUserModal
                    user={showDeleteModal}
                    closeModal={() => setShowDeleteModal(null)}
                />
            )}
        </>
    );
};

interface UsersTableProps {
    users: Record<number, User>;
    isFetching: boolean;
}

export default UsersTable;
