import {
    fetchAllAssessmentsFailure,
    fetchAllAssessmentsRequest,
    fetchAllAssessmentsSuccess,
} from '@actions/assessments';
import {
    fetchAssessmentFailure,
    fetchAssessmentRequest,
    fetchAssessmentSuccess,
} from '@actions/assessments/fetchAssessment';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { convertArrToObj } from 'lib/src/utils/generic';
import { AllTypesOfAssessment, Assessment } from 'src/types/shared/Assessment';

interface AssessmentState {
    isFetching: boolean;
    fetchError: string | null;
    assessment: AllTypesOfAssessment | null;
    assessments: Record<number, Assessment>;
}

const initialState: AssessmentState = {
    isFetching: false,
    fetchError: null,
    assessment: null,
    assessments: {},
};

export default createReducer(initialState, {
    [fetchAssessmentRequest.type]: handleFetchRequest,
    [fetchAssessmentSuccess.type]: handleFetchAssessmentSuccess,
    [fetchAssessmentFailure.type]: handleFetchError,
    [fetchAllAssessmentsRequest.type]: handleFetchRequest,
    [fetchAllAssessmentsSuccess.type]: handleFetchAssessmentsSuccess,
    [fetchAllAssessmentsFailure.type]: handleFetchError,
});

function handleFetchRequest(state: AssessmentState) {
    state.isFetching = true;
    state.fetchError = null;
}

function handleFetchError(state: AssessmentState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.fetchError = action.payload;
}

function handleFetchAssessmentSuccess(
    state: AssessmentState,
    action: PayloadAction<AllTypesOfAssessment>,
) {
    state.isFetching = false;
    state.assessment = action.payload;
}

function handleFetchAssessmentsSuccess(
    state: AssessmentState,
    action: PayloadAction<Assessment[]>,
) {
    state.isFetching = false;
    state.assessments = convertArrToObj(action.payload);
}
