import ActionButton from 'lib/src/components/button/ActionButton';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import Modal from 'lib/src/components/modal/Modal';
import FailedUsersTable from '../FailedUsersTable';
import useFailedUploads from '../hooks/useFailedUploads';

const UploadFailuresModal: React.FC<UploadUsersCSVModalProps> = ({ closeModal }) => {
    const { failedUsers, numberOfFailedUsers, downloadFailedUsersCSV } = useFailedUploads();

    const handleDownload = () => {
        downloadFailedUsersCSV();
        closeModal();
    };

    return (
        <Modal title="Failed to upload some users">
            <p>
                {numberOfFailedUsers} user{numberOfFailedUsers !== 1 ? 's' : ''} could not be
                uploaded:
            </p>
            <FailedUsersTable users={failedUsers} isFetching={false} />
            <div style={{ minHeight: '2em' }} />
            <ButtonRow alignment="right">
                <ActionButton source="secondary" type="button" onClick={closeModal}>
                    Cancel
                </ActionButton>
                <ActionButton type="button" onClick={handleDownload}>
                    Download CSV
                </ActionButton>
            </ButtonRow>
        </Modal>
    );
};

interface UploadUsersCSVModalProps {
    closeModal: VoidFunction;
}

export default UploadFailuresModal;
