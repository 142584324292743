import { useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { fetchEntities, fetchEntitiesSimple } from '@actions/entities';
import {
    getEntities,
    getEntitiesArr,
    getEntitiesFetchError,
    getEntitiesIsFetching,
    getEntitiesSimpleArr,
} from '@selectors/entities';

const useFetchEntities = () => {
    const dispatch = useDispatch();
    const entities = useSelector(getEntities);
    const entitiesArr = useSelector(getEntitiesArr);
    const entitiesSimpleArr = useSelector(getEntitiesSimpleArr);
    const isFetching = useSelector(getEntitiesIsFetching);
    const fetchError = useSelector(getEntitiesFetchError);

    useEffect(() => {
        batch(() => {
            dispatch(fetchEntities());
            dispatch(fetchEntitiesSimple());
        });
    }, [dispatch]);

    return { entities, entitiesArr, entitiesSimpleArr, isFetching, fetchError };
};

export default useFetchEntities;
