import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { ComparisonParams, ComparisonResponse } from 'src/types/shared/Comparison';

export const compareJobRolesRequest = createAction('compareJobRolesRequest');
export const compareJobRolesSuccess = createAction<ComparisonResponse>('compareJobRolesSuccess');
export const compareJobRolesFailure = createAction('compareJobRolesFailure');

export const compareJobRoles =
    (params: ComparisonParams) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(compareJobRolesRequest());
        try {
            const { data }: CreateSearchJobRolesResponse = await api.get(
                'job-roles/assessment-results',
                params,
            );
            dispatch(compareJobRolesSuccess(data));
            console.log(data);
        } catch (e) {
            handleApiErrors(dispatch, compareJobRolesFailure, e as APIError);
        }
    };

interface CreateSearchJobRolesResponse {
    data: ComparisonResponse;
}
