import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import FlexWrapper from '../layout/wrapper/FlexWrapper';

const AssessmentChart: React.FC<AssessmentChartProps> = ({
    topics,
    behaviours,
    dataPoints,
    chartStats,
    isSmallVersion = false,
    isAdminVersion = false,
    showPercentageOnClick,
}) => {
    const [isStartOfAnimation, setIsStartOfAnimation] = useState(true);
    const [visiblePercentageBehaviourID, setVisiblePercentageBehaviourID] = useState<number | null>(
        null,
    );

    useEffect(() => {
        setTimeout(() => setIsStartOfAnimation(false), 100);
    }, []);

    if (!topics.length || !behaviours.length) {
        return null;
    }

    return (
        <>
            <FlexWrapper extraClasses={`assessment-chart-outer ${isSmallVersion ? 'small' : ''}`}>
                <FlexWrapper
                    direction="column"
                    align="end"
                    justify="between"
                    extraClasses="assessment-info left"
                >
                    <div className="score top left" style={{ borderColor: topics[3].colour }}>
                        <p className="score-value">{topics[3].percentage}%</p>
                        <p className="score-title">{topics[3].name}</p>
                        {chartStats &&
                            chartStats[3] &&
                            chartStats[3].stats.map(stat => (
                                <React.Fragment key={stat.name}>
                                    <p className="score-subvalue">{stat.percentage}%</p>
                                    <p className="score-subtitle">{stat.name}</p>
                                </React.Fragment>
                            ))}
                    </div>

                    <div className="score bottom left" style={{ borderColor: topics[2].colour }}>
                        <p className="score-value">{topics[2].percentage}%</p>
                        <p className="score-title">{topics[2].name}</p>
                        {chartStats &&
                            chartStats[2] &&
                            chartStats[2].stats.map(stat => (
                                <React.Fragment key={stat.name}>
                                    <p className="score-subvalue">{stat.percentage}%</p>
                                    <p className="score-subtitle">{stat.name}</p>
                                </React.Fragment>
                            ))}
                    </div>
                </FlexWrapper>

                <div className="assessment-chart">
                    <div className="divider vertical" />
                    <div className="divider horizontal" />
                    <div className="circle centre" />

                    {/* Concentric circles at percentage lines */}
                    {circleData.map(({ width }) => {
                        return (
                            <div
                                key={width}
                                className={'circle'}
                                style={{
                                    width: `${width}%`,
                                    height: `${width}%`,
                                    marginTop: `-${width / 2}%`,
                                    marginLeft: `-${width / 2}%`,
                                }}
                            />
                        );
                    })}

                    {/* Behaviour categories & dividing lines */}
                    {behaviours.map((behaviour, index) => {
                        // most calculations here are based on 8 circles from centre, 4 topics and 16 behaviours (4 per topic)
                        const degrees = 360 / behaviours.length;
                        const rotation = `rotate(${degrees * index}deg)`;

                        const hiddenTextBlockIndexes: number[] = [5, 7, 13, 15];

                        // BELOW MATHS WORKS BUT UNCLEAR ON WHY THESE VALUES WORK, MIGHT NOT WORK IF CHART ENDS UP MORE DYNAMIC OR TOPICS / BEHAVIOURS ADDED / REMOVED
                        let marginPercentage = 24; // 24 would be 100% (up to final bar), every bar after that will be +2 (eg 95% is 26)
                        const marginPercentageDiff =
                            (2 / 5) * (100 - behaviour.selfAssessmentPercentage);
                        marginPercentage += marginPercentageDiff;

                        // setting heights and locations of peer dividers (orange lines joining up)
                        const minVisibleScore = circleData[0].percentage - 5;

                        const largeDividerIndexes = [0, 4, 8, 12];
                        const isLargeDivider = largeDividerIndexes.includes(index);
                        const isRightOrBottom = index === 4 || index === 8;

                        const behaviourDataPoints = dataPoints.find(
                            item => item.id === behaviour.id,
                        );

                        const prevBehaviourDataPoints = dataPoints[index - 1]
                            ? dataPoints[index - 1]
                            : dataPoints[dataPoints.length - 1];

                        return (
                            <React.Fragment key={behaviour.name}>
                                {/* Coloured amounts radiating from center */}
                                <div
                                    className="slice"
                                    style={{
                                        transform: rotation,
                                        margin: `${
                                            isStartOfAnimation
                                                ? minVisibleScore - 10
                                                : marginPercentage
                                        }%`,
                                    }}
                                />

                                {showPercentageOnClick && (
                                    <button
                                        className="slice percentage-button"
                                        style={{
                                            transform: rotation,
                                            margin: '0%',
                                        }}
                                        onClick={() => {
                                            if (visiblePercentageBehaviourID === behaviour.id) {
                                                setVisiblePercentageBehaviourID(null);
                                            } else {
                                                setVisiblePercentageBehaviourID(behaviour.id);
                                            }
                                        }}
                                    />
                                )}

                                <div
                                    className="divider pie-slice"
                                    style={{ transform: rotation }}
                                />

                                {!hiddenTextBlockIndexes.includes(index) && (
                                    <div
                                        className="divider pie-slice text-block-container"
                                        style={{ transform: rotation }}
                                    >
                                        <div className="block" />
                                    </div>
                                )}

                                {behaviourDataPoints &&
                                    behaviourDataPoints.points.map((dataPoint, index) => {
                                        const prevDataPoimt = prevBehaviourDataPoints.points[index];

                                        const minimisedDataPointPercentage =
                                            dataPoint.percentage < minVisibleScore
                                                ? minVisibleScore
                                                : dataPoint.percentage;
                                        const minimisedPrevDataPointPercentage =
                                            prevDataPoimt.percentage < minVisibleScore
                                                ? minVisibleScore
                                                : prevDataPoimt.percentage;

                                        let marginPercentageDataPoint = 24;
                                        const marginPercentageDiffDataPoint =
                                            (2 / 5) * (100 - minimisedDataPointPercentage);
                                        marginPercentageDataPoint += marginPercentageDiffDataPoint;

                                        const dataPointDiffFromNext = Math.abs(
                                            minimisedDataPointPercentage -
                                                minimisedPrevDataPointPercentage,
                                        );

                                        let dataPointDividerPercentageHeight =
                                            dataPointDiffFromNext / 2.5;
                                        let dataPointDividerPercentageFromBottom = minVisibleScore;

                                        const smallestScore = Math.min(
                                            minimisedDataPointPercentage,
                                            minimisedPrevDataPointPercentage,
                                        );

                                        // only need to update bottom if both are above the minimum visible score allowed on chart
                                        if (
                                            minimisedDataPointPercentage >= minVisibleScore &&
                                            minimisedPrevDataPointPercentage >= minVisibleScore
                                        ) {
                                            const diffBetweenMinVisible =
                                                smallestScore - minVisibleScore;
                                            dataPointDividerPercentageFromBottom =
                                                diffBetweenMinVisible / 2.5 + minVisibleScore + 12; // adding 12 seems to fix positioning...
                                        } else {
                                            dataPointDividerPercentageHeight =
                                                dataPointDividerPercentageHeight -
                                                (minVisibleScore - smallestScore) / 2.5;
                                        }

                                        return (
                                            <React.Fragment key={dataPoint.name}>
                                                <div
                                                    className={'slice peer'}
                                                    style={{
                                                        transform: rotation,
                                                        margin: `${
                                                            isStartOfAnimation
                                                                ? minVisibleScore - 10
                                                                : marginPercentageDataPoint
                                                        }%`,
                                                        borderColor: dataPoint.colour,
                                                    }}
                                                />

                                                <div
                                                    className="divider pie-slice peer"
                                                    style={{ transform: rotation }}
                                                >
                                                    <div
                                                        className="peer-divider"
                                                        style={{
                                                            width: `${
                                                                !isLargeDivider ? '3' : '5'
                                                            }px`,
                                                            left: `-${
                                                                !isLargeDivider
                                                                    ? '1.25'
                                                                    : isRightOrBottom
                                                                    ? '1'
                                                                    : '1.5'
                                                            }px`,
                                                            height: `calc(${dataPointDividerPercentageHeight}% + 3px)`,
                                                            bottom: `calc(${dataPointDividerPercentageFromBottom}% - 3px)`,
                                                            backgroundColor: dataPoint.colour,
                                                        }}
                                                    />
                                                </div>
                                            </React.Fragment>
                                        );
                                    })}

                                <div
                                    className="behaviour-name-wrapper"
                                    style={{ transform: rotation }}
                                >
                                    <p
                                        className="text"
                                        style={{
                                            transform: `rotate(${behaviour.rotationAmount}deg)`,
                                        }}
                                    >
                                        {behaviour.name}
                                        {behaviour.id === visiblePercentageBehaviourID && (
                                            <>
                                                <br />
                                                {behaviour.selfAssessmentPercentage.toFixed(0)}%
                                            </>
                                        )}
                                        <br />
                                    </p>
                                </div>
                            </React.Fragment>
                        );
                    })}

                    {/* Clickable areas */}
                    {!isAdminVersion &&
                        !showPercentageOnClick &&
                        topics.map((topic, index) => {
                            const clickClasses = [
                                'top right',
                                'bottom right',
                                'bottom left',
                                'top left',
                            ];
                            return (
                                <Link
                                    to={`/latest-assessment/details?topic=${topic.id}`}
                                    key={topic.id}
                                    className={`topic-click ${clickClasses[index]}`}
                                />
                            );
                        })}
                </div>

                <FlexWrapper
                    direction="column"
                    justify="between"
                    extraClasses="assessment-info right"
                >
                    <div className="score top right" style={{ borderColor: topics[0].colour }}>
                        <p className="score-value">{topics[0].percentage}%</p>
                        <p className="score-title">{topics[0].name}</p>
                        {chartStats &&
                            chartStats[0] &&
                            chartStats[0].stats.map(stat => (
                                <React.Fragment key={stat.name}>
                                    <p className="score-subvalue">{stat.percentage}%</p>
                                    <p className="score-subtitle">{stat.name}</p>
                                </React.Fragment>
                            ))}
                    </div>
                    <div className="score bottom right" style={{ borderColor: topics[1].colour }}>
                        <p className="score-value">{topics[1].percentage}%</p>
                        <p className="score-title">{topics[1].name}</p>
                        {chartStats &&
                            chartStats[1] &&
                            chartStats[1].stats.map(stat => (
                                <React.Fragment key={stat.name}>
                                    <p className="score-subvalue">{stat.percentage}%</p>
                                    <p className="score-subtitle">{stat.name}</p>
                                </React.Fragment>
                            ))}
                    </div>
                </FlexWrapper>
            </FlexWrapper>

            {!!dataPoints[0].points.length && (
                <FlexWrapper extraClasses="chart-key-wrapper" direction="column" align="end">
                    {dataPoints[0].points.map(dataPoint => (
                        <div key={dataPoint.name} className="chart-key">
                            <div className="bar" style={{ backgroundColor: dataPoint.colour }} />
                            <p>{dataPoint.name}</p>
                        </div>
                    ))}
                </FlexWrapper>
            )}
        </>
    );
};

const circleData: CircleData[] = [
    {
        width: 8,
        percentage: 45,
    },
    {
        width: 12,
        percentage: 50,
    },
    {
        width: 16,
        percentage: 55,
    },
    {
        width: 20,
        percentage: 60,
    },
    {
        width: 24,
        percentage: 65,
    },
    {
        width: 28,
        percentage: 70,
    },
    {
        width: 32,
        percentage: 75,
    },
    {
        width: 36,
        percentage: 80,
    },
    {
        width: 40,
        percentage: 85,
    },
    {
        width: 44,
        percentage: 90,
    },
    {
        width: 48,
        percentage: 95,
    },
    {
        width: 52,
        percentage: 100,
    },
];

interface AssessmentChartProps {
    topics: ChartTopic[];
    behaviours: ChartBehaviour[];
    dataPoints: BehaviourDataPoints[];
    chartStats?: ChartStats[];
    isSmallVersion?: boolean;
    isAdminVersion?: boolean;
    showPercentageOnClick?: boolean;
}

export interface ChartTopic {
    id: number;
    name: string;
    summary: string;
    colour: string;
    colourDark: string;
    percentage: number;
    sort: number;
}

export interface ChartBehaviour {
    id: number;
    name: string;
    colour: string;
    colourDark: string;
    selfAssessmentPercentage: number;
    peerAssessmentPercentage: number;
    rotationAmount: number;
}

export interface BehaviourDataPoints {
    id: number;
    points: DataPoint[];
}

export interface ChartStats {
    stats: ChartStat[];
}

export interface ChartStat {
    name: string;
    percentage: number;
}

interface DataPoint {
    name: string;
    colour: string;
    percentage: number;
}

interface CircleData {
    width: number;
    percentage: number;
}

export default AssessmentChart;
