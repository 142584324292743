import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Entity } from 'src/types/shared/Entity';

import { getEntitiesSimple } from '@selectors/entities';
import { entityTypeNames } from 'lib/src/shared/enums/entityEnums';

import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import ActionButton from 'lib/src/components/button/ActionButton';
import EditEntityModal from './modals/EditEntityModal';
import { selectCurrentUser } from '@selectors/users';
import { userRoles } from 'lib/src/shared/enums/userEnums';

const EntityConfiguration: React.FC<EntityConfigurationProps> = ({ entity }) => {
    const [showEditEntityModal, setShowEditEntityModal] = useState(false);

    const entitiesSimple = useSelector(getEntitiesSimple);
    const curUser = useSelector(selectCurrentUser);

    return (
        <>
            <ContentBlock>
                <p className="page-subtitle">Entity Configuration</p>
                <p>Name: {entity.name}</p>
                <p>
                    Parent Entity:{' '}
                    {entity.parentEntityID && entitiesSimple[entity.parentEntityID]
                        ? entitiesSimple[entity.parentEntityID].name
                        : 'N/A'}
                </p>
                <p>Code: {entity.code}</p>
                <p style={{ marginBottom: 15 }}>Type: {entityTypeNames[entity.type]}</p>

                {curUser?.role === userRoles.administrator && (
                    <ActionButton
                        source="secondary"
                        icon="edit"
                        onClick={() => setShowEditEntityModal(true)}
                    >
                        Edit
                    </ActionButton>
                )}
            </ContentBlock>

            {showEditEntityModal && (
                <EditEntityModal entity={entity} closeModal={() => setShowEditEntityModal(false)} />
            )}
        </>
    );
};

interface EntityConfigurationProps {
    entity: Entity;
}

export default EntityConfiguration;
