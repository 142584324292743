import {
    fetchUserAssessmentFailure,
    fetchUserAssessmentRequest,
    fetchUserAssessmentSuccess,
} from '@actions/userAssessments';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { AllTypesOfAssessment } from 'src/types/shared/Assessment';

interface UserAssessmentState {
    isFetching: boolean;
    fetchError: string | null;
    userAssessment: AllTypesOfAssessment[] | null;
}

const initialState: UserAssessmentState = {
    isFetching: false,
    fetchError: null,
    userAssessment: null,
};

export default createReducer(initialState, {
    [fetchUserAssessmentRequest.type]: handleFetchRequest,
    [fetchUserAssessmentSuccess.type]: handleFetchUserAssessmentSuccess,
    [fetchUserAssessmentFailure.type]: handleFetchError,
});

function handleFetchRequest(state: UserAssessmentState) {
    state.isFetching = true;
    state.fetchError = null;
}

function handleFetchError(state: UserAssessmentState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.fetchError = action.payload;
}

function handleFetchUserAssessmentSuccess(
    state: UserAssessmentState,
    action: PayloadAction<AllTypesOfAssessment[]>,
) {
    state.isFetching = false;
    state.userAssessment = action.payload;
}
