import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { AllTypesOfAssessment } from 'src/types/shared/Assessment';

export const fetchUserAssessmentRequest = createAction('fetchUserAssessmentRequest');
export const fetchUserAssessmentSuccess = createAction<AllTypesOfAssessment>(
    'fetchUserAssessmentSuccess',
);
export const fetchUserAssessmentFailure = createAction('fetchUserAssessmentFailure');

export const fetchUserAssessment =
    (id: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchUserAssessmentRequest());
        try {
            const { data } = await api.get<AllTypesOfAssessment>(`assessment/user/${id}`);
            dispatch(fetchUserAssessmentSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchUserAssessmentFailure, e as APIError);
        }
    };
