import { useMemo, useState } from 'react';
import qs from 'qs';

import { JobRole } from 'src/types/shared/JobRole';

const useJobRolesSelection = () => {
    const [selected, setSelected] = useState<JobRole[]>([]);

    const isSelected = (jobRoleID: number) => {
        if (selected.map(curJobRole => curJobRole.id).includes(jobRoleID)) {
            return true;
        }

        return false;
    };

    const updateSelection = (jobRole: JobRole) => {
        const isJobRoleSelected = isSelected(jobRole.id);

        if (isJobRoleSelected) {
            const newSelectedValue = selected.filter(item => item.id !== jobRole.id);
            setSelected(newSelectedValue);
        } else {
            const newSelectedValue = [...selected, jobRole];
            setSelected(newSelectedValue);
        }
    };

    const isMaxedOut = selected.length > 3;

    const comparisonLink = useMemo((): string => {
        const link = '/job-role-search/compare';
        const paramsData = {
            ids: selected.map(jobRole => jobRole.id + ''),
        };

        const params = qs.stringify(paramsData, { arrayFormat: 'repeat' });
        return `${link}?${params}`;
    }, [selected]);

    return { selected, isSelected, updateSelection, isMaxedOut, comparisonLink };
};

export default useJobRolesSelection;
