import Form from 'lib/src/components/form/Form';
import Description from 'lib/src/components/typography/Description';
import Modal from './Modal';

const ConfirmModal: React.FC<ConfirmModalProps> = ({
    closeModal,
    handleSubmit,
    error,
    isPosting,
    title = 'Confirm',
    description = 'Are you sure?',
}) => {
    return (
        <Modal closeModal={closeModal} title={title} size="small">
            <Description>{description}</Description>
            <Form
                onSubmit={handleSubmit}
                onCancel={closeModal}
                isPosting={isPosting}
                error={error}
                submitText="Delete"
            />
        </Modal>
    );
};
interface ConfirmModalProps {
    closeModal: () => void;
    handleSubmit: () => void;
    error?: string | null;
    isPosting?: boolean;
    title?: string;
    description?: string;
}

export default ConfirmModal;
