// entity types
export enum entityTypes {
    region = 1,
    retailOutlet = 2,
    office = 3,
    department = 4,
}

export const entityTypeNames = {
    [entityTypes.region]: 'Region',
    [entityTypes.retailOutlet]: 'Retail Outlet',
    [entityTypes.office]: 'Office',
    [entityTypes.department]: 'Department',
};
