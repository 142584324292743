import React, { SyntheticEvent, useCallback } from 'react';

import useFieldValidation from '../../hooks/useFieldValidation';
import { FormInputProps } from '../../types/shared/FormInputProps';
import FormField from './FormField';

const NumberInput = ({
    name,
    value,
    onChange,
    label = '',
    placeholder = '',
    required = false,
    validationRegExp,
    minNumber,
    maxNumber,
    disabled,
    customValidate,
}: NumberInputProps) => {
    const memoizedValidate = useCallback(_validate, [minNumber, maxNumber, validationRegExp]);
    const [error, showError] = useFieldValidation({
        name,
        required,
        value,
        customValidate,
        extendedValidate: memoizedValidate,
    });

    return (
        <FormField name={name} label={label} required={required} error={error}>
            <input
                type="number"
                className={`form-input text-area number-input ${error ? 'error' : ''}`}
                placeholder={placeholder}
                name={name}
                id={name}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disabled}
                min={minNumber}
                max={maxNumber}
                data-testid={`${name}-input`}
            />
        </FormField>
    );

    function handleBlur() {
        showError();
    }

    function handleChange(e: SyntheticEvent) {
        e.preventDefault();

        const newVal = parseInt((e.target as HTMLInputElement).value);
        onChange(name, newVal);
    }

    function _validate(val: string | number) {
        if (!val) return;

        if (minNumber && val < minNumber) {
            return `Value cannot be less than ${minNumber},`;
        }
        if (maxNumber && val > maxNumber) {
            return `Value cannot be greater than ${maxNumber}.`;
        }
    }
};

interface NumberInputProps extends FormInputProps<number> {
    placeholder?: string;
    label?: string;
    validationRegExp?: string;
    minNumber?: number;
    maxNumber?: number;
}

export default NumberInput;
