import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllUsers } from '@actions/users/fetchAllUsers';
import { selectUsers, selectUsersError, selectUsersIsFetching } from '@selectors/users';

const useUsersFetch = () => {
    const dispatch = useDispatch();
    const users = useSelector(selectUsers);
    const isFetching = useSelector(selectUsersIsFetching);
    const error = useSelector(selectUsersError);

    useEffect(() => {
        dispatch(fetchAllUsers());
    }, [dispatch]);

    return { users, isFetching, error };
};

export default useUsersFetch;
