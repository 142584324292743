// user roles
export enum userRoles {
    administrator = 100,
    hr = 10,
    leader = 1,
}

export const userRoleNames = {
    [userRoles.administrator]: 'Administrator',
    [userRoles.hr]: 'HR',
    [userRoles.leader]: 'Leader',
};
