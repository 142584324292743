import { useCallback, useState } from 'react';

export default function useForm<TForm>(initialData: TForm): UseForm<TForm> {
    const [formData, setFormData] = useState<TForm>(initialData);

    const handleChange = useCallback(<T>(name: keyof TForm, value: T) => {
        setFormData((prev: TForm) => ({ ...prev, [name]: value }));
    }, []);

    const resetData = useCallback((data: TForm) => {
        setFormData(data);
    }, []);

    return [formData, handleChange, resetData];
}

type UseForm<TForm> = [
    formData: TForm,
    handleChange: <T>(name: keyof TForm, value: T) => void,
    resetData: (data: TForm) => void,
];
