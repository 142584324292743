import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getEntitiesPostError } from '@selectors/entities';

import usePrevious from 'lib/src/hooks/usePrevious';

import Title from 'lib/src/components/typography/Title';
import CreateHeader from '@components/layout/createHeader/CreateHeader';
import ActionButton from 'lib/src/components/button/ActionButton';
import EntitiesTable from './EntitiesTable';
import CreateEntityModal from './modals/CreateEntityModal';
import ErrorModal from 'lib/src/components/modal/ErrorModal';
import Meta from 'lib/src/components/meta/Meta';
import { selectCurrentUser } from '@selectors/users';
import { userRoles } from 'lib/src/shared/enums/userEnums';

const Entities: React.FC = () => {
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const postError = useSelector(getEntitiesPostError);
    const prevPostError = usePrevious(postError);
    const curUser = useSelector(selectCurrentUser);

    useEffect(() => {
        if (postError && !prevPostError) setShowErrorModal(true);
    }, [postError, prevPostError]);

    return (
        <>
            <Meta title="Entity Hierarchy" />
            <CreateHeader>
                <Title>Entity Hierarchy</Title>
                {curUser?.role === userRoles.administrator && (
                    <ActionButton
                        source="positive"
                        icon="plus"
                        onClick={() => setShowCreateModal(true)}
                    >
                        Create Entity
                    </ActionButton>
                )}
            </CreateHeader>

            <EntitiesTable />

            {showCreateModal && <CreateEntityModal closeModal={() => setShowCreateModal(false)} />}
            {showErrorModal && (
                <ErrorModal
                    description={postError ? postError : undefined}
                    closeModal={() => setShowErrorModal(false)}
                />
            )}
        </>
    );
};

export default Entities;
