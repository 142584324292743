import { selectUsersFailedUploadCSVString, selectUsersFailedUploadUsers } from '@selectors/users';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

const useFailedUploads = () => {
    const failedUsers = useSelector(selectUsersFailedUploadUsers);
    const failedCSVString = useSelector(selectUsersFailedUploadCSVString);
    const numberOfFailedUsers = Object.keys(failedUsers).length;

    const downloadFailedUsersCSV = () => {
        const csvContent =
            'data:text/csv;charset=utf-8,' +
            'uuid,firstName,lastName,role,entityCode,\n' +
            failedCSVString;

        const filename = `Failed user uploads ${dayjs().format('YYYY-MM-DD')}.csv`;

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', filename);
        document.body.appendChild(link); // Required for FF
        link.click();
        document.body.removeChild(link);
    };

    return {
        failedUsers,
        numberOfFailedUsers,
        downloadFailedUsersCSV,
    };
};

export default useFailedUploads;
