import { Route, Switch, useRouteMatch } from 'react-router-dom';

import DefaultRedirect from './DefaultRedirect';
import Entities from '@pages/entities/entities/Entities';
import Entity from '@pages/entities/entity/Entity';

const EntitiesRoutes: React.FC = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`}>
                <Entities />
            </Route>
            <Route exact path={`${path}/:id`}>
                <Entity />
            </Route>
            <DefaultRedirect to={path} />
        </Switch>
    );
};

export default EntitiesRoutes;
