import { useMemo } from 'react';
import { LeaderResults, PeerAverageResult, PeerBehaviour } from 'src/types/shared/Assessment';
import { TopicsBehaviour } from 'src/types/shared/TopicsBehaviors';
import { BehaviourDataPoints, ChartTopic } from 'lib/src/components/charts/AssessmentChart';
import { DATA_POINT_COLOURS } from 'lib/src/constants/chart';

const useBehaviourDataPointsForAssessmentChart = (
    topicsBehaviors: TopicsBehaviour,
    assessment: LeaderResults,
    peerAverageResults: PeerAverageResult[],
    topicsForAssessmentChart: ChartTopic[],
) => {
    const latestPeerAverageResult = peerAverageResults.find(
        result => result.parentAssessmentID === assessment.assessmentID,
    );

    const behaviourDataPointsForAssessmentChart: BehaviourDataPoints[] = useMemo(() => {
        const behaviours = [...topicsBehaviors.behaviours]
            .map(behaviour => ({
                ...behaviour,
                sort:
                    topicsForAssessmentChart.find(topic => topic.id === behaviour.topicID)?.sort ||
                    0,
            }))
            .sort((a, b) => a.sort - b.sort || a.topicID - b.topicID || a.id - b.id)
            .map(behaviour => {
                let relatedPeerAssessmentBehaviour: PeerBehaviour | undefined = undefined;

                if (latestPeerAverageResult) {
                    const relatedPeerAssessmentTopic = latestPeerAverageResult.topics.find(
                        t => t.id === behaviour.topicID,
                    );
                    relatedPeerAssessmentBehaviour = relatedPeerAssessmentTopic?.behaviours.find(
                        b => b.id === behaviour.id,
                    );
                }

                return {
                    id: behaviour.id,
                    points: [
                        {
                            name: 'Zone of excellence',
                            colour: DATA_POINT_COLOURS.ZONE_OF_EXCELLENCE,
                            percentage: 95,
                        },
                        {
                            name: 'Peer assessment',
                            colour: DATA_POINT_COLOURS.SECOND,
                            percentage: relatedPeerAssessmentBehaviour?.percentage || 0,
                        },
                    ],
                };
            });
        return behaviours;
    }, [topicsBehaviors.behaviours, latestPeerAverageResult, topicsForAssessmentChart]);

    return behaviourDataPointsForAssessmentChart;
};

export default useBehaviourDataPointsForAssessmentChart;
