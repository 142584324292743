import { Column } from '../../types/table';
import TablePagination from './TablePagination';
import TableSearch from './TableSearch';
import useTable from './useTable';

export interface Row {
    key?: number;
    id?: number | string;
}

const Table = <TColumn, TRow extends Row>({
    columns = [],
    rows = [],
    isLoading = false,
    pageSizes = [10, 25, 100],
    itemsPerPage,
    noDataMessage = 'There is no data to display',
    fetchError,
    searchBarLabel,
    placeholder,
    hasTooltips = false,
    hidePagination = false,
    hideSearch = false,
}: TableProps<TRow>): JSX.Element => {
    const {
        paginatedRows,
        handleSortClick,
        sortColumn,
        sortAsc,
        page,
        setPage,
        maxPage,
        pageSizeOpts,
        pageSize,
        setPageSize,
        paginationDescription,
        searchTerm,
        setSearchTerm,
        canSearch,
    } = useTable<TRow>({
        columns,
        rows,
        pageSizes,
        itemsPerPage,
        hasTooltips,
    });

    const isEmpty = !rows.length;
    return (
        <>
            {canSearch && !isEmpty && !hideSearch && (
                <TableSearch
                    value={searchTerm}
                    onChange={setSearchTerm}
                    searchBarLabel={searchBarLabel}
                    placeholder={placeholder}
                />
            )}
            <table>
                <thead>
                    <tr>{columns.map(renderHeading)}</tr>
                </thead>
                <tbody>
                    {isLoading && (
                        <tr>
                            <td className="loading-row" colSpan={columns.length}>
                                <i className="fal fa-spinner fa-spin"></i>
                            </td>
                        </tr>
                    )}
                    {isEmpty && !isLoading && fetchError ? (
                        <tr className="no-data-row">
                            <td colSpan={columns.length}>{fetchError}</td>
                        </tr>
                    ) : isEmpty && !isLoading && !fetchError ? (
                        <tr className="no-data-row">
                            <td colSpan={columns.length}>{noDataMessage}</td>
                        </tr>
                    ) : null}
                    {paginatedRows.map(row => (
                        <tr key={row.key || row.id}>
                            {columns.map(col => {
                                const onClick = col.onClick;
                                return (
                                    <td
                                        key={col.key}
                                        onClick={!!onClick ? () => onClick(row) : undefined}
                                        className={`${!!onClick ? 'clickable' : ''}`}
                                    >
                                        {col.getValue(row)}
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
            {!isEmpty && !hidePagination && (
                <TablePagination
                    page={page}
                    setPage={setPage}
                    maxPage={maxPage}
                    pageSizeOpts={pageSizeOpts}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    description={paginationDescription}
                    itemsPerPage={itemsPerPage}
                />
            )}
        </>
    );

    function renderHeading(col: Column<TRow>) {
        const { key, heading, getSort } = col;
        const canSort = getSort !== undefined;
        const isSorting = sortColumn?.key === key;

        return (
            <th
                key={key}
                className={canSort ? 'sortable' : ''}
                onClick={e => handleSortClick(e, col)}
            >
                {isSorting && sortAsc && <i className="fas fa-sort-up" />}
                {isSorting && !sortAsc && <i className="fas fa-sort-down" />}
                {heading}
            </th>
        );
    }
};

interface TableProps<T> {
    columns: Column<T>[];
    rows: T[];
    isLoading?: boolean;
    pageSizes?: number[];
    itemsPerPage?: number | undefined;
    noDataMessage?: string;
    fetchError?: string | null;
    searchBarLabel?: string;
    placeholder?: string;
    hasTooltips?: boolean;
    hidePagination?: boolean;
    hideSearch?: boolean;
    onRowClick?: (row: T) => void;
}

export default Table;
