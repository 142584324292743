import { useSelector } from 'react-redux';

import Table from 'lib/src/components/table/Table';
import { CSVUser } from 'src/types/shared/User';
import { selectUsersError } from '@selectors/users';
import { useMemo } from 'react';
import { Column } from 'lib/src/types/table';

interface CSVTableUser extends CSVUser {
    id: string;
}

const FailedUsersTable: React.FC<FailedUsersTableProps> = ({ users, isFetching }) => {
    const fetchError = useSelector(selectUsersError);
    const formattedUsers = useMemo(
        () => Object.values(users).map((user: CSVUser) => ({ ...user, id: user.UUID })),
        [users],
    );

    const columns: Column<CSVTableUser>[] = [
        {
            key: 1,
            heading: 'UUID',
            getValue: (row: CSVTableUser) => `${row.UUID}`,
            getSort: (a: CSVTableUser, b: CSVTableUser) => a.UUID.localeCompare(b.UUID),
            searchable: true,
        },
        {
            key: 2,
            heading: 'First Name',
            getValue: (row: CSVTableUser) => `${row.FirstName}`,
            getSort: (a: CSVTableUser, b: CSVTableUser) => a.FirstName.localeCompare(b.FirstName),
            searchable: true,
        },
        {
            key: 3,
            heading: 'Last Name',
            getValue: (row: CSVTableUser) => `${row.LastName}`,
            getSort: (a: CSVTableUser, b: CSVTableUser) => a.LastName.localeCompare(b.LastName),
            searchable: true,
        },
        {
            key: 4,
            heading: 'Type',
            getValue: (row: CSVTableUser) => row.Type,
            searchable: true,
        },

        {
            key: 5,
            heading: 'Attached To Entity',
            getValue: (row: CSVTableUser) => row.AttachedToEntity || '-',
            searchable: true,
        },
    ];

    return (
        <Table
            columns={columns}
            rows={formattedUsers}
            isLoading={isFetching}
            fetchError={fetchError}
            hidePagination
            hideSearch
        />
    );
};

interface FailedUsersTableProps {
    users: Record<string, CSVUser>;
    isFetching: boolean;
}

export default FailedUsersTable;
