import { useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { createEntity, fetchEntities, fetchEntitiesSimple } from '@actions/entities';
import {
    getEntitiesArr,
    getEntitiesIsPosting,
    getEntitiesPostError,
    getEntitiesPostSuccess,
} from '@selectors/entities';

import useForm from 'lib/src/hooks/useForm';
import usePrevious from 'lib/src/hooks/usePrevious';
import { DropdownOption } from 'lib/src/types/shared/DropdownOption';

const useCreateEntity = (closeModal: VoidFunction) => {
    const dispatch = useDispatch();
    const isPosting = useSelector(getEntitiesIsPosting);
    const postError = useSelector(getEntitiesPostError);
    const postSuccess = useSelector(getEntitiesPostSuccess);
    const entities = useSelector(getEntitiesArr);

    const prevProps = usePrevious({ postError, postSuccess });

    const [form, handleChange] = useForm({
        name: '',
        code: '',
        type: null,
        parentEntityID: null,
    });

    const handleSubmit = () => {
        dispatch(createEntity(form));
    };

    useEffect(() => {
        if (postError && !prevProps.postError) {
            closeModal();
        }
    }, [postError, prevProps.postError, closeModal]);

    useEffect(() => {
        if (postSuccess && !prevProps.postSuccess) {
            batch(() => {
                dispatch(fetchEntities());
                dispatch(fetchEntitiesSimple());
            });
            closeModal();
        }
    }, [dispatch, postSuccess, prevProps.postSuccess, closeModal]);

    const entitiesOptions = entities.reduce((acc: DropdownOption<number>[], entity) => {
        acc.push({
            value: entity.id,
            label: entity.name,
        });

        if (!!entity.children.length) {
            entity.children.forEach(child => {
                acc.push({
                    value: child.id,
                    label: child.name,
                });
            });
        }

        return acc;
    }, []);

    return { form, handleChange, handleSubmit, isPosting, entitiesOptions };
};

export default useCreateEntity;
