import React from 'react';

const ColouredSquare: React.FC<ColouredSquareProps> = ({ colour = 'purple' }) => {
    return (
        <div
            className="coloured-square"
            data-testid="coloured-square"
            style={{ backgroundColor: colour }}
        ></div>
    );
};

interface ColouredSquareProps {
    colour?: string;
}

export default ColouredSquare;
