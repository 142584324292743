import { JobRole } from 'src/types/shared/JobRole';

import Table from 'lib/src/components/table/Table';
import ActionButton from 'lib/src/components/button/ActionButton';
import ButtonRow from 'lib/src/components/button/ButtonRow';

const JobRolesTable: React.FC<JobRolesTableProps> = ({
    jobRoles,
    isFetching,
    error,
    isSelected,
    updateSelection,
    isMaxedOut,
}) => {
    const columns = [
        {
            key: 1,
            heading: 'ID',
            getValue: (row: JobRole) => `${row.id}`,
            getSort: (a: JobRole, b: JobRole) => {
                const aStringID = a.id + '';
                const bStringID = b.id + '';

                return aStringID.localeCompare(bStringID);
            },
        },
        {
            key: 2,
            heading: 'Job Role Name',
            getValue: (row: JobRole) => `${row.name}`,
            getSort: (a: JobRole, b: JobRole) => a.name.localeCompare(b.name),
        },
        {
            key: 3,
            heading: '',
            getValue: (row: JobRole) => {
                const selected = isSelected(row.id);
                const disabled = !selected && isMaxedOut;

                return (
                    <ButtonRow alignment="right">
                        <ActionButton
                            onClick={() => updateSelection(row)}
                            disabled={disabled}
                            source={selected ? 'positive' : 'primary'}
                            icon={selected ? 'check' : undefined}
                            tooltip={
                                disabled
                                    ? 'Max items selected (4), please remove one to add one'
                                    : undefined
                            }
                        >
                            {selected ? 'Selected' : 'Select'}
                        </ActionButton>
                    </ButtonRow>
                );
            },
        },
    ];

    return (
        <Table
            columns={columns}
            rows={Object.values(jobRoles)}
            isLoading={isFetching}
            fetchError={error}
        />
    );
};

interface JobRolesTableProps {
    jobRoles: Record<number, JobRole>;
    isFetching: boolean;
    error: string | null;
    isSelected: (jobRoleID: number) => boolean;
    updateSelection: (jobRole: JobRole) => void;
    isMaxedOut: boolean;
}

export default JobRolesTable;
