import { useEffect, useState } from 'react';
import { useEventListener } from './useEventListener';

const useWindowDimensions = (): WindowDimensionsProps => {
    const [windowSize, setWindowSize] = useState<WindowDimensionsProps>({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEventListener(window, 'resize', handleResize);

    useEffect(() => {
        handleResize();
    }, []);

    return windowSize;

    function handleResize() {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }
};

interface WindowDimensionsProps {
    width: number;
    height: number;
}

export default useWindowDimensions;
