import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    getJobRoles,
    getJobRolesSearchIsPosting,
    getJobRolesSearchSearchError,
} from '@selectors/jobRoles';
import { searchJobRoles } from '@actions/jobRoles';

import useForm from 'lib/src/hooks/useForm';

const useJobRoleSearch = () => {
    const dispatch = useDispatch();

    const isPosting = useSelector(getJobRolesSearchIsPosting);
    const jobRoles = useSelector(getJobRoles);
    const error = useSelector(getJobRolesSearchSearchError);

    const [formState, handleChange] = useForm({
        searchTerm: '',
    });

    const [tableWasSearched, setTableWasSearched] = useState(false);

    const handleSubmit = useCallback(() => {
        dispatch(searchJobRoles(formState.searchTerm));
        setTableWasSearched(true);
    }, [dispatch, formState]);

    useEffect(() => {
        dispatch(searchJobRoles(''));
    }, [dispatch]);

    return {
        isPosting,
        error,
        formState,
        handleChange,
        handleSubmit,
        jobRoles,
        tableWasSearched,
    };
};

export default useJobRoleSearch;
