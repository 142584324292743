import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getDecodedIDToken, getJwtToken, validateJWT } from '../utils/jwt';

export default function useIsLoggedIn() {
    const [token, setToken] = useState(getJwtToken());
    const [idToken, setIDToken] = useState(getDecodedIDToken());
    const location = useLocation();

    useEffect(() => {
        setToken(getJwtToken());
        setIDToken(getDecodedIDToken());
    }, [location]);
    if (!token) return { isLoggedIn: false, id: null };
    const isValidToken = validateJWT(token);
    return { isLoggedIn: isValidToken, id: idToken };
}
