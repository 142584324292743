import React from 'react';

import { Entity } from 'src/types/shared/Entity';

import { convertEnumToDropdownOption } from 'lib/src/shared/enums/dropdownEnums';
import { entityTypeNames } from 'lib/src/shared/enums/entityEnums';

import useEditEntity from '../hooks/useEditEntity';

import Modal from 'lib/src/components/modal/Modal';
import Form from 'lib/src/components/form/Form';
import TextInput from 'lib/src/components/form/TextInput';
import Select from 'lib/src/components/form/Select';

const EditEntityModal: React.FC<EditEntityModalProps> = ({ entity, closeModal }) => {
    const { form, handleChange, handleSubmit, isPosting } = useEditEntity(entity, closeModal);

    const typeOptions = convertEnumToDropdownOption(entityTypeNames);

    return (
        <Modal title={`Edit ${entity.name}`}>
            <Form isPosting={isPosting} onSubmit={handleSubmit} onCancel={closeModal}>
                <TextInput
                    name="name"
                    label="Name"
                    value={form.name}
                    onChange={handleChange}
                    required
                />
                <TextInput
                    name="code"
                    label="Code"
                    value={form.code}
                    onChange={handleChange}
                    required
                />
                <Select
                    name="type"
                    label="Type"
                    value={form.type}
                    options={typeOptions}
                    onChange={handleChange}
                    required
                    forceOptionsAbove
                />
            </Form>
        </Modal>
    );
};

interface EditEntityModalProps {
    entity: Entity;
    closeModal: VoidFunction;
}

export default EditEntityModal;
