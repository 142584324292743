import dev from './dev.config';
import staging from './staging.config';
import production from './production.config';

const env: Environment = (process.env.REACT_APP_ENVIRONMENT as Environment) || 'dev';
const configs = {
    dev,
    staging,
    production,
};

//console.log({ env, config: configs[env] });
export default configs[env];

export interface Config {
    API_URL: string;
    OAUTH_URL: string;
    OAUTH_CLIENT_ID: string;
    OAUTH_REDIRECT_URL: string;
    OAUTH_ACCESS_TOKEN_URL: string;
    OAUTH_SCOPE: string;
}

type Environment = 'dev' | 'staging' | 'production';
