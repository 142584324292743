import { useMemo } from 'react';
import { TopicsBehaviour } from 'src/types/shared/TopicsBehaviors';
import { BehaviourDataPoints, ChartTopic } from 'lib/src/components/charts/AssessmentChart';
import { DATA_POINT_COLOURS } from 'lib/src/constants/chart';
import { AnchorResult, ComparisonResult } from 'src/types/shared/Comparison';

const useBehaviourDataPointsForCompareChart = (
    topicsBehaviors: TopicsBehaviour,
    topicsForAssessmentChart: ChartTopic[],
    compareResults: ComparisonResult[],
    leaderResults: AnchorResult | null,
) => {
    const behaviourDataPointsForCompareChart: BehaviourDataPoints[] = useMemo(() => {
        const behaviours = [...topicsBehaviors.behaviours]
            .map(behaviour => ({
                ...behaviour,
                sort:
                    topicsForAssessmentChart.find(topic => topic.id === behaviour.topicID)?.sort ||
                    0,
            }))
            .sort((a, b) => a.sort - b.sort || a.topicID - b.topicID || a.id - b.id)
            .map(behaviour => {
                const colours = [
                    DATA_POINT_COLOURS.SECOND,
                    DATA_POINT_COLOURS.THIRD,
                    DATA_POINT_COLOURS.FOURTH,
                    DATA_POINT_COLOURS.FIFTH,
                ];

                const comparePoints = compareResults.map((result, index) => {
                    const relatedCompareTopic = result.topicResults.find(
                        topic => topic.id === behaviour.topicID,
                    );
                    const relatedCompareBehaviour = relatedCompareTopic?.behaviours.find(
                        b => b.id === behaviour.id,
                    );

                    return {
                        name: result.friendlyName,
                        colour: colours[index] || '#000',
                        percentage: relatedCompareBehaviour?.percentage || 0,
                    };
                });

                let leaderPoint: DataPoint = {
                    name: '',
                    colour: '',
                    percentage: 0,
                };

                if (leaderResults) {
                    const relatedLeaderCompareTopic = leaderResults.topicResults.find(
                        topic => topic.id === behaviour.topicID,
                    );
                    const relatedLeaderCompareBehaviour =
                        relatedLeaderCompareTopic?.behaviours.find(b => b.id === behaviour.id);

                    leaderPoint = {
                        name: leaderResults.friendlyName,
                        colour: DATA_POINT_COLOURS.FIFTH || '#000',
                        percentage: relatedLeaderCompareBehaviour?.percentage || 0,
                    };
                }

                const points = [
                    {
                        name: 'Zone of excellence',
                        colour: DATA_POINT_COLOURS.ZONE_OF_EXCELLENCE,
                        percentage: 95,
                    },
                    ...comparePoints,
                ];

                if (leaderResults) {
                    points.push(leaderPoint);
                }

                return {
                    id: behaviour.id,
                    points,
                };
            });
        return behaviours;
    }, [compareResults, topicsBehaviors.behaviours, topicsForAssessmentChart, leaderResults]);

    return behaviourDataPointsForCompareChart;
};

interface DataPoint {
    name: string;
    colour: string;
    percentage: number;
}

export default useBehaviourDataPointsForCompareChart;
