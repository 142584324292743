import React, { Dispatch, SetStateAction } from 'react';

const Tabs: React.FC<TabsProps> = ({ tabs, selectedTabID, setSelectedTabID }) => (
    <div className="tab-container">
        {tabs.map(tab => (
            <button
                key={tab.id}
                onClick={() => setSelectedTabID(tab.id)}
                className={`tab ${tab.id === selectedTabID ? 'active' : ''}`}
                type="button"
            >
                {tab.name}
            </button>
        ))}
    </div>
);

interface TabsProps {
    tabs: Tab[];
    selectedTabID: number;
    setSelectedTabID: Dispatch<SetStateAction<number>>;
}

export interface Tab {
    id: number;
    name: string;
}

export default Tabs;
