import { RootState } from '@reducers/index';
import { Entity, EntitySimple } from 'src/types/shared/Entity';

export const getEntities = (state: RootState): Record<number, Entity> =>
    state.entitiesReducer.entities;
export const getEntitiesArr = (state: RootState): Entity[] =>
    Object.values(state.entitiesReducer.entities);
export const getEntity = (state: RootState): Entity | null => state.entitiesReducer.entity;
export const getEntitiesIsFetching = (state: RootState): boolean =>
    state.entitiesReducer.isFetching;
export const getEntitiesIsPosting = (state: RootState): boolean => state.entitiesReducer.isPosting;
export const getEntitiesFetchError = (state: RootState): string | null =>
    state.entitiesReducer.fetchError;
export const getEntitiesPostError = (state: RootState): string | null =>
    state.entitiesReducer.postError;
export const getEntitiesPostSuccess = (state: RootState): boolean =>
    state.entitiesReducer.postSuccess;

// simple entities
export const getEntitiesSimple = (state: RootState): Record<number, EntitySimple> =>
    state.entitiesReducer.entitiesSimple;
export const getEntitiesSimpleArr = (state: RootState): EntitySimple[] =>
    Object.values(state.entitiesReducer.entitiesSimple);
export const getEntitySimple = (state: RootState, id: number): EntitySimple =>
    state.entitiesReducer.entitiesSimple[id] || null;

// creation
export const getNewlyAddedEntityID = (state: RootState): number | null =>
    state.entitiesReducer.newlyAddedEntityID;
