import { useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { fetchEntitiesSimple, fetchEntity } from '@actions/entities';
import { getEntitiesFetchError, getEntitiesIsFetching, getEntity } from '@selectors/entities';

const useFetchEntity = (id: number) => {
    const dispatch = useDispatch();
    const entity = useSelector(getEntity);
    const isFetching = useSelector(getEntitiesIsFetching);
    const fetchError = useSelector(getEntitiesFetchError);

    useEffect(() => {
        batch(() => {
            dispatch(fetchEntity(id));
            dispatch(fetchEntitiesSimple());
        });
    }, [dispatch, id]);

    return { entity, isFetching, fetchError };
};

export default useFetchEntity;
