import { compareJobRoles } from '@actions/jobRoles';
import {
    getAnchorJobRole,
    getComparisonJobRoles,
    getJobRolesComparisonError,
    getJobRolesComparisonIsFetching,
} from '@selectors/jobRoles';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ComparisonParams } from 'src/types/shared/Comparison';

const useJobRolesComparisonFetch = () => {
    const dispatch = useDispatch();
    const [searched, setSearched] = useState(false);
    const searchParams = useLocation().search;
    const params: ParamsProps = qs.parse(searchParams, { ignoreQueryPrefix: true });
    const ids = params.ids;

    const anchorJobRole = useSelector(getAnchorJobRole);
    const comparisonJobRoles = useSelector(getComparisonJobRoles);
    const isFetching = useSelector(getJobRolesComparisonIsFetching);
    const error = useSelector(getJobRolesComparisonError);

    useEffect(() => {
        if (!!ids && Array.isArray(ids) && ids.length > 1 && !searched) {
            const apiParams: ComparisonParams = {
                anchorId: +ids[0],
                comparisonIds: ids.slice(1).map(id => +id),
            };

            dispatch(compareJobRoles(apiParams));
            setSearched(true);
        }
    }, [dispatch, params, ids, searched]);

    return { anchorJobRole, comparisonJobRoles, isFetching, error };
};

interface ParamsProps {
    ids?: string | string[];
}

export default useJobRolesComparisonFetch;
