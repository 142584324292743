import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';

const LinkButton: React.FC<LinkButtonProps> = ({
    source = 'primary',
    href = '',
    extraClasses = '',
    containerClasses = '',
    icon,
    children,
    width = 'auto',
    onClick,
    openInNewTab,
}) => {
    const [isClicked, setIsClicked] = useState(false);
    const { scale } = useSpring({ scale: isClicked ? 0.8 : 1 });

    return (
        <animated.div
            className={containerClasses}
            onMouseDown={() => setIsClicked(true)}
            onMouseUp={() => setIsClicked(false)}
            onMouseLeave={() => setIsClicked(false)}
            style={{ transform: scale.to((x: number) => `scale(${x})`), width: `${width}` }}
            onClick={onClick}
        >
            <Link
                to={href}
                className={`button ${source} ${extraClasses}`}
                target={openInNewTab ? '_blank' : '_self'}
                rel={openInNewTab ? 'noopener noreferrer' : undefined}
            >
                {icon && <i className={`icon far fa-fw fa-${icon}`}></i>}
                <span className="text">{children}</span>
            </Link>
        </animated.div>
    );
};

interface LinkButtonProps {
    children: React.ReactNode;
    href: string;
    source?: 'primary' | 'secondary' | 'positive' | 'negative';
    icon?: string;
    extraClasses?: string;
    containerClasses?: string;
    width?: string;
    onClick?: () => void;
    openInNewTab?: boolean;
}

export default LinkButton;
