import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { Leader, LeaderSearchRequest } from 'src/types/shared/LeaderSearch';

export const searchLeadersRequest = createAction('searchLeadersRequest');
export const searchLeadersSuccess = createAction<Leader[]>('searchLeadersSuccess');
export const searchLeadersFailure = createAction('searchLeadersFailure');

export const searchLeaders =
    (postbody: LeaderSearchRequest) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(searchLeadersRequest());
        try {
            const { data }: CreateSearchLeadersResponse = await api.post('users/search', postbody);
            dispatch(searchLeadersSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, searchLeadersFailure, e as APIError);
        }
    };

interface CreateSearchLeadersResponse {
    data: Leader[];
}
