import ActionButton from 'lib/src/components/button/ActionButton';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import Modal from 'lib/src/components/modal/Modal';

const UploadUsersSuccessModal: React.FC<UploadUsersSuccessModalProps> = ({ closeModal }) => {
    return (
        <Modal title="Successfully uploaded all users">
            <p>All users were successfully imported.</p>
            <ButtonRow alignment="right">
                <ActionButton type="button" onClick={closeModal}>
                    OK
                </ActionButton>
            </ButtonRow>
        </Modal>
    );
};

interface UploadUsersSuccessModalProps {
    closeModal: VoidFunction;
}

export default UploadUsersSuccessModal;
