import React, { useState } from 'react';

import { Entity } from 'src/types/shared/Entity';

import Tabs, { Tab } from 'lib/src/components/tabs/Tabs';
import FlexWrapper from 'lib/src/components/layout/wrapper/FlexWrapper';
import EntityLeaders from './tabs/EntityLeaders';
import EntityHRStaff from './tabs/EntityHRStaff';
import EntityNestedLeaders from './tabs/EntityNestedLeaders';
import EntityNestedHRStaff from './tabs/EntityNestedHRStaff';
import { selectCurrentUser } from '@selectors/users';
import { userRoles } from 'lib/src/shared/enums/userEnums';
import { useSelector } from 'react-redux';

export const entityUsersTabs: Tab[] = [
    {
        id: 1,
        name: 'Leaders',
    },
    {
        id: 2,
        name: 'HR Staff',
    },
    {
        id: 3,
        name: 'Nested Leaders',
    },
    {
        id: 4,
        name: 'Nested HR Staff',
    },
];

const EntityUsers: React.FC<EntityUsersProps> = ({ entity }) => {
    const [selectedTabID, setSelectedTabID] = useState(entityUsersTabs[0].id);
    const curUser = useSelector(selectCurrentUser);
    const isAdmin = curUser?.role === userRoles.administrator;

    const routes: Record<number, () => JSX.Element> = {
        1: () => <EntityLeaders entity={entity} isAdmin={isAdmin} />,
        2: () => <EntityHRStaff entity={entity} isAdmin={isAdmin} />,
        3: () => <EntityNestedLeaders entity={entity} isAdmin={isAdmin} />,
        4: () => <EntityNestedHRStaff entity={entity} isAdmin={isAdmin} />,
    };

    const SpecificRoute = routes[selectedTabID];

    return (
        <FlexWrapper direction="column">
            <Tabs
                tabs={entityUsersTabs}
                selectedTabID={selectedTabID}
                setSelectedTabID={setSelectedTabID}
            />

            <SpecificRoute />
        </FlexWrapper>
    );
};

interface EntityUsersProps {
    entity: Entity;
}

export default EntityUsers;
