import { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

const AssessmentResult: React.FC<AssessmentResultProps> = ({ percentage, percentageDrift }) => {
    useEffect(() => {
        ReactTooltip.rebuild();
    }, []);

    const direction = percentageDrift && percentageDrift < 0 ? 'below' : 'above';
    const tooltip =
        percentageDrift === 0
            ? 'This Leader is at the average of all other results in this search'
            : !!percentageDrift
            ? `This Leader is ${Math.abs(
                  percentageDrift,
              )}% ${direction} the average of all other results in this search`
            : '';

    return (
        <div className="flex-row">
            {typeof percentage === 'number' && (
                <div
                    className="assessmentResult percentageResult"
                    style={{ backgroundColor: 'var( --overall)', color: 'white' }}
                >
                    {percentage}
                </div>
            )}

            {!!percentageDrift && (
                <div
                    className={`assessmentResult ${
                        direction === 'below' ? 'flex-column-reverse' : 'flex-column'
                    }`}
                    data-tip={tooltip}
                >
                    <div
                        className={`assessment-arrow ${
                            direction === 'below' ? 'down' : 'up'
                        } justify-start`}
                    ></div>
                    {Math.abs(percentageDrift)}
                </div>
            )}
        </div>
    );
};

interface AssessmentResultProps {
    percentage?: number | null;
    percentageDrift?: number | null;
}

export default AssessmentResult;
