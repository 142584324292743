import { useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { OAuthPopup } from '@tasoskakour/react-use-oauth2';

const AuthLoading = () => {
    const search = useLocation().search;
    const history = useHistory();
    const params = decodeURI(search)
        .replace('?', '')
        .split('&')
        .map(param => param.split('='))
        .reduce<Record<string, string>>((values, [key, value]) => {
            values[key] = value;
            return values;
        }, {});
    const { code } = params;
    useEffect(() => {
        if (!code) {
            history.push('/entities');
        }
    }, [code, history]);
    if (!code) return null;
    return <OAuthPopup />;
};

export default AuthLoading;
