import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { JobRole } from 'src/types/shared/JobRole';

export const searchJobRolesRequest = createAction('searchJobRolesRequest');
export const searchJobRolesSuccess = createAction<JobRole[]>('searchJobRolesSuccess');
export const searchJobRolesFailure = createAction('searchJobRolesFailure');

export const searchJobRoles =
    (searchTerm: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(searchJobRolesRequest());
        try {
            const { data }: CreateSearchJobRolesResponse = await api.get(`job-roles`, {
                searchTerm,
            });

            dispatch(searchJobRolesSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, searchJobRolesFailure, e as APIError);
        }
    };

interface CreateSearchJobRolesResponse {
    data: JobRole[];
}
