import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
    searchJobRolesFailure,
    searchJobRolesRequest,
    searchJobRolesSuccess,
    compareJobRolesRequest,
    compareJobRolesSuccess,
    compareJobRolesFailure,
} from '@actions/jobRoles';
import { JobRole } from 'src/types/shared/JobRole';
import { AnchorResult, ComparisonResponse, ComparisonResult } from 'src/types/shared/Comparison';

interface JobRolesState {
    isPosting: boolean;
    postError: string | null;
    jobRoles: { [key: number]: JobRole };
    isFetchingComparison: boolean;
    fetchComparisonError: string | null;
    anchorResult: AnchorResult | null;
    comparisonResults: ComparisonResult[] | null;
}

const initialState: JobRolesState = {
    isPosting: false,
    postError: null,
    jobRoles: {},
    isFetchingComparison: false,
    fetchComparisonError: null,
    anchorResult: null,
    comparisonResults: null,
};

export default createReducer(initialState, {
    [searchJobRolesRequest.type]: handleSearchJobRolesRequest,
    [searchJobRolesSuccess.type]: handleSearchJobRolesSuccess,
    [searchJobRolesFailure.type]: handleSearchJobRolesError,
    [compareJobRolesRequest.type]: handleCompareJobRolesRequest,
    [compareJobRolesSuccess.type]: handleCompareJobRolesSuccess,
    [compareJobRolesFailure.type]: handleCompareJobRolesError,
});

function handleSearchJobRolesRequest(state: JobRolesState) {
    state.isPosting = true;
    state.postError = null;
}

function handleSearchJobRolesError(state: JobRolesState, action: PayloadAction<string>) {
    state.postError = action.payload;
    state.isPosting = false;
}

function handleSearchJobRolesSuccess(state: JobRolesState, action: PayloadAction<JobRole[]>) {
    state.isPosting = false;
    state.jobRoles = action.payload;
}

function handleCompareJobRolesRequest(state: JobRolesState) {
    state.isFetchingComparison = true;
    state.fetchComparisonError = null;
    state.anchorResult = null;
    state.comparisonResults = null;
}

function handleCompareJobRolesError(state: JobRolesState, action: PayloadAction<string>) {
    state.fetchComparisonError = action.payload;
    state.isFetchingComparison = false;
}

function handleCompareJobRolesSuccess(
    state: JobRolesState,
    action: PayloadAction<ComparisonResponse>,
) {
    state.isFetchingComparison = false;
    state.anchorResult = action.payload.anchorResults;
    state.comparisonResults = action.payload.comparisonResults;
}
