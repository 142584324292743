import { useMemo } from 'react';
import { TopicsBehaviour } from 'src/types/shared/TopicsBehaviors';
import { chartRotationValues } from 'lib/src/constants/chart';
import { ChartBehaviour, ChartTopic } from 'lib/src/components/charts/AssessmentChart';
import { AnchorResult, ComparisonResult } from 'src/types/shared/Comparison';

const useTopicsAndBehavioursForCompareChart = (
    topicsBehaviors: TopicsBehaviour,
    assessment: AnchorResult,
    compareResults: ComparisonResult[],
) => {
    const topicsForAssessmentChart: ChartTopic[] = useMemo(
        () =>
            [...topicsBehaviors.topics]
                .sort((a, b) => a.sort - b.sort)
                .map(topic => {
                    const percentage =
                        assessment.topicResults.find(t => t.id === topic.id)?.percentage || 0;

                    const comparePercentages = compareResults.map(res => {
                        const relTopic = res.topicResults.find(t => t.id === topic.id);
                        return relTopic?.percentage || 0;
                    });

                    const allPercentages = [percentage, ...comparePercentages];
                    const average =
                        allPercentages.reduce((acc, curPercentage) => acc + curPercentage, 0) /
                        allPercentages.length;

                    return {
                        ...topic,
                        percentage: +average.toFixed(0),
                    };
                }),
        [topicsBehaviors.topics, assessment.topicResults, compareResults],
    );

    const behavioursForAssessmentChart: ChartBehaviour[] = useMemo(() => {
        const behaviours = [...topicsBehaviors.behaviours]
            .map(behaviour => ({
                ...behaviour,
                sort:
                    topicsForAssessmentChart.find(topic => topic.id === behaviour.topicID)?.sort ||
                    0,
            }))
            .sort((a, b) => a.sort - b.sort || a.topicID - b.topicID || a.id - b.id)
            .map((behaviour, index) => {
                // related self assessment information
                const relatedSelfAssessmentTopic = assessment.topicResults.find(
                    t => t.id === behaviour.topicID,
                );
                const relatedSelfAssessmentBehaviour = relatedSelfAssessmentTopic?.behaviours.find(
                    b => b.id === behaviour.id,
                );

                const rawTopic = topicsBehaviors.topics.find(t => t.id === behaviour.topicID);

                return {
                    id: behaviour.id,
                    name: behaviour.name,
                    colour: rawTopic?.colour || '#CCC',
                    colourDark: rawTopic?.colourDark || '#CCC',
                    selfAssessmentPercentage: relatedSelfAssessmentBehaviour?.percentage || 0,
                    peerAssessmentPercentage: 0,
                    rotationAmount: chartRotationValues[index] || 0,
                };
            });
        return behaviours;
    }, [
        topicsBehaviors.behaviours,
        assessment.topicResults,
        topicsBehaviors.topics,
        topicsForAssessmentChart,
    ]);

    return {
        topicsForAssessmentChart,
        behavioursForAssessmentChart,
    };
};

export default useTopicsAndBehavioursForCompareChart;
