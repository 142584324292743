import { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import ReactTooltip from 'react-tooltip';

const ActionButton: React.FC<ActionButtonProps> = ({
    children,
    type = 'submit',
    source = 'primary',
    icon,
    width,
    autoWidth = true,
    isPosting = false,
    disabled = false,
    extraClasses = '',
    onClick = () => {},
    tooltip,
}) => {
    const [isClicked, setIsClicked] = useState(false);
    const { scale } = useSpring({ scale: isClicked ? 0.8 : 1 });
    const blockWidth = autoWidth ? '' : `width-${width}`;

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [tooltip]);

    return (
        <animated.button
            className={`button ${source} ${
                isClicked ? 'clicked' : ''
            } ${extraClasses} ${blockWidth}`}
            type={type}
            disabled={isPosting || disabled}
            onClick={onClick}
            onMouseDown={() => setIsClicked(true)}
            onMouseUp={() => setIsClicked(false)}
            onMouseLeave={() => setIsClicked(false)}
            style={{ transform: scale.to((x: number) => `scale(${x})`) }}
            data-tip={tooltip}
        >
            {icon && !isPosting && <i className={`icon far fa-fw fa-${icon}`}></i>}
            {isPosting && <i className="icon far fa-fw fa-spinner fa-spin"></i>}
            <span className="text">{children}</span>
        </animated.button>
    );
};

export interface ActionButtonProps {
    children: React.ReactNode;
    type?: 'submit' | 'button' | 'reset';
    source?: 'primary' | 'secondary' | 'positive' | 'negative';
    icon?: string;
    isPosting?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    width?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    autoWidth?: boolean;
    disabled?: boolean;
    extraClasses?: string;
    tooltip?: string;
}

export default ActionButton;
