import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchUserAssessment } from '@actions/userAssessments';
import {
    getUserAssessment,
    getUserAssessmentFetchError,
    getUserAssessmentIsFetching,
} from '@selectors/userAssessment';

const useFetchUserAssessment = (id: number) => {
    const dispatch = useDispatch();
    const assessments = useSelector(getUserAssessment);
    const isFetching = useSelector(getUserAssessmentIsFetching);
    const fetchError = useSelector(getUserAssessmentFetchError);

    useEffect(() => {
        dispatch(fetchUserAssessment(id));
    }, [dispatch, id]);

    return { assessments, isFetching, fetchError };
};

export default useFetchUserAssessment;
