import { Route, Switch, useRouteMatch } from 'react-router-dom';

import DefaultRedirect from './DefaultRedirect';
import LeadersSearch from '@pages/leaderSearch/search/LeaderSearch';
import LeadersComparison from '@pages/leaderSearch/comparison/LeadersComparison';

const LeadersSearchRoutes: React.FC = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`}>
                <LeadersSearch />
            </Route>
            <Route exact path={`${path}/compare`}>
                <LeadersComparison />
            </Route>
            <DefaultRedirect to={path} />
        </Switch>
    );
};

export default LeadersSearchRoutes;
