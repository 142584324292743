import dayjs from 'dayjs';

import Table from 'lib/src/components/table/Table';
import { Leader } from 'src/types/shared/LeaderSearch';
import AssessmentResult from './AssessmentResult';
import useFetchEntities from '@pages/entities/entities/hooks/useFetchEntities';

import { DATE_FORMAT } from 'lib/src/constants/dateFormats';
import ActionButton from 'lib/src/components/button/ActionButton';
import ButtonRow from 'lib/src/components/button/ButtonRow';

const LeadersTable: React.FC<LeadersTableProps> = ({
    leaders,
    isFetching,
    error,
    isSelected,
    isSelectedLeader,
    updateSelection,
    isMaxedOut,
}) => {
    const { entitiesSimpleArr } = useFetchEntities();

    const columns = [
        {
            key: 1,
            heading: 'UUID',
            getValue: (row: Leader) => `${row.uuid}`,
            getSort: (a: Leader, b: Leader) => a.uuid.localeCompare(b.uuid),
        },
        {
            key: 2,
            heading: 'First Name',
            getValue: (row: Leader) => `${row.firstName}`,
            getSort: (a: Leader, b: Leader) => a.firstName.localeCompare(b.firstName),
        },
        {
            key: 3,
            heading: 'Last Name',
            getValue: (row: Leader) => `${row.lastName}`,
            getSort: (a: Leader, b: Leader) => a.lastName.localeCompare(b.lastName),
        },
        {
            key: 4,
            heading: 'Attached Entity',
            getValue: (row: Leader) =>
                row.entityID
                    ? entitiesSimpleArr.find(entity => entity.id === row.entityID)?.name
                    : '-',
        },
        {
            key: 5,
            heading: 'Self Assessment',
            getValue: (row: Leader) =>
                row.latestAssessment?.assessmentResults ? (
                    <AssessmentResult
                        percentage={row.latestAssessment?.assessmentResults?.percentage}
                        percentageDrift={row?.percentageDrift}
                    />
                ) : (
                    '-'
                ),
        },
        {
            key: 6,
            heading: 'Peer Assessment',
            getValue: (row: Leader) =>
                row.latestAssessment?.peerAssessmentResults ? (
                    <AssessmentResult
                        percentage={row.latestAssessment?.peerAssessmentResults?.percentage}
                        percentageDrift={row?.peerPercentageDrift}
                    />
                ) : (
                    '-'
                ),
        },
        {
            key: 7,
            heading: 'Latest Assessment Date',
            getValue: (row: Leader) => {
                const latestAssessmentDate = row.latestAssessment?.assessmentResults?.createdOn;
                const latestPeerAssessmentDate =
                    row.latestAssessment?.peerAssessmentResults?.createdOn;

                if (!latestAssessmentDate && !latestPeerAssessmentDate) {
                    return '-';
                }

                return +new Date(latestAssessmentDate) >= +new Date(latestPeerAssessmentDate)
                    ? dayjs(latestAssessmentDate).format(DATE_FORMAT)
                    : dayjs(latestPeerAssessmentDate).format(DATE_FORMAT);
            },
        },
        {
            key: 8,
            heading: '',
            getValue: (row: Leader) => {
                const selected = isSelected(row.id);
                const selectedLeader = isSelectedLeader(row.id);
                const userDisabled = !selected && isMaxedOut;
                const leaderDisabled = selected;

                return (
                    <ButtonRow alignment="right">
                        <ActionButton
                            onClick={() => updateSelection(row, false)}
                            disabled={selectedLeader || userDisabled}
                            source={selected ? 'positive' : 'primary'}
                            icon={selected ? 'check' : undefined}
                            tooltip={
                                selectedLeader
                                    ? 'User is selected as Leader'
                                    : userDisabled
                                    ? 'Max items selected (4), please remove one to add one'
                                    : undefined
                            }
                        >
                            {selected ? 'Selected User' : 'Select User'}
                        </ActionButton>

                        <ActionButton
                            onClick={() => updateSelection(row, true)}
                            disabled={leaderDisabled}
                            source={selectedLeader ? 'positive' : 'primary'}
                            icon={selectedLeader ? 'check' : undefined}
                            tooltip={
                                leaderDisabled
                                    ? 'User is already chosen in comparison, please remove to select as Leader'
                                    : undefined
                            }
                        >
                            {selectedLeader ? 'Selected Leader' : 'Select Leader'}
                        </ActionButton>
                    </ButtonRow>
                );
            },
        },
    ];

    return (
        <Table
            columns={columns}
            rows={Object.values(leaders)}
            isLoading={isFetching}
            fetchError={error}
        />
    );
};
interface LeadersTableProps {
    leaders: Record<number, Leader>;
    isFetching: boolean;
    error: string | null;
    isSelected: (leaderID: number) => boolean;
    isSelectedLeader: (leaderID: number) => boolean;
    updateSelection: (leader: Leader, isLeader: boolean) => void;
    isMaxedOut: boolean;
}

export default LeadersTable;
