import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { Assessment } from 'src/types/shared/Assessment';

export const fetchAllAssessmentsRequest = createAction('fetchAllAssessmentsRequest');
export const fetchAllAssessmentsSuccess = createAction<Assessment[]>('fetchAllAssessmentsSuccess');
export const fetchAllAssessmentsFailure = createAction('fetchAllAssessmentsFailure');

export const fetchAllAssessments =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchAllAssessmentsRequest());
        try {
            const { data } = await api.get<Assessment[]>('assessment');
            dispatch(fetchAllAssessmentsSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchAllAssessmentsFailure, e as APIError);
        }
    };
