import { Link } from 'react-router-dom';

import DecathlonLogo from 'lib/src/_content/logos/logo-white.svg';
import useDarkMode from 'lib/src/hooks/useDarkMode';
import ToggleSwitch from 'lib/src/components/form/ToggleSwitch';
import useIsLoggedIn from 'lib/src/hooks/useIsLoggedIn';

const Header: React.FC = () => {
    const [darkMode, setDarkMode] = useDarkMode() as [boolean, (value: boolean) => void];
    const { id } = useIsLoggedIn();

    return (
        <header className="header">
            <div className="logo">
                <Link to="/" className="logo">
                    <img alt="decathlon logo" src={DecathlonLogo} />
                </Link>
            </div>
            <div className="flex-row">
                <div className="dark-mode-switch">
                    <ToggleSwitch
                        label="Dark mode"
                        name="darkmode"
                        onChange={() => setDarkMode(!darkMode)}
                        value={darkMode}
                        textColour="white"
                    />
                </div>
                <div className="profile-icon-wrapper">
                    <div className="flex-column align-end">
                        <p>{id?.displayName}</p>
                        <p>{id?.title}</p>
                    </div>

                    <div className="profile-icon">
                        <i className="fa fa-user"></i>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
