import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Entity } from 'src/types/shared/Entity';

import { editEntity, fetchEntitiesSimple } from '@actions/entities';
import {
    getEntitiesIsPosting,
    getEntitiesPostError,
    getEntitiesPostSuccess,
    getNewlyAddedEntityID,
} from '@selectors/entities';

import useForm from 'lib/src/hooks/useForm';
import usePrevious from 'lib/src/hooks/usePrevious';

const useEditEntity = (entity: Entity, closeModal: VoidFunction) => {
    const dispatch = useDispatch();
    const isPosting = useSelector(getEntitiesIsPosting);
    const postError = useSelector(getEntitiesPostError);
    const postSuccess = useSelector(getEntitiesPostSuccess);
    const newlyAddedEntityID = useSelector(getNewlyAddedEntityID);

    const prevProps = usePrevious({ postError, postSuccess, newlyAddedEntityID });

    const [form, handleChange] = useForm({
        name: entity.name,
        code: entity.code,
        type: entity.type,
    });

    const handleSubmit = () => {
        dispatch(editEntity(entity.id, form));
    };

    useEffect(() => {
        if (postError && !prevProps.postError) {
            closeModal();
        }
    }, [postError, prevProps.postError, closeModal]);

    useEffect(() => {
        if (postSuccess && !prevProps.postSuccess) {
            dispatch(fetchEntitiesSimple());
            closeModal();
        }
    }, [dispatch, postSuccess, prevProps.postSuccess, closeModal]);

    return { form, handleChange, handleSubmit, isPosting };
};

export default useEditEntity;
