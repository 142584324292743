import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import UserContainer from '@pages/users/user/UserContainer';
import Users from '@pages/users/users/Users';
import DefaultRedirect from './DefaultRedirect';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '@selectors/users';
import { userRoles } from 'lib/src/shared/enums/userEnums';
import { useEffect } from 'react';

const UsersRoutes: React.FC = () => {
    const { path } = useRouteMatch();
    const history = useHistory();

    const curUser = useSelector(selectCurrentUser);
    const isHr = curUser?.role === userRoles.hr;

    useEffect(() => {
        if (isHr) {
            history.replace('/');
        }
    }, [isHr, history]);

    if (isHr) return null;

    return (
        <Switch>
            <Route exact path={`${path}`}>
                <Users />
            </Route>
            <Route exact path={`${path}/:id`}>
                <UserContainer />
            </Route>
            <DefaultRedirect to={path} />
        </Switch>
    );
};

export default UsersRoutes;
