import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';
import RawFileUpload from 'lib/src/components/form/RawFileUpload';
import Modal from 'lib/src/components/modal/Modal';
import useUploadUsersCSV from '../hooks/useUploadUsersCSV';

const UploadUsersCSVModal: React.FC<UploadUsersCSVModalProps> = ({
    closeModal,
    showUploadFailuresModal,
    showSuccessModal,
}) => {
    const {
        isPosting,
        error,
        handleChange,
        handleSubmit,
        formState: { file },
    } = useUploadUsersCSV(closeModal, showUploadFailuresModal, showSuccessModal);

    return (
        <Modal title="Upload Users CSV">
            <Form onSubmit={handleSubmit} isPosting={isPosting} onCancel={closeModal} error={error}>
                <FormRow>
                    <RawFileUpload
                        label="Upload a CSV file containing the new users."
                        accept=".csv"
                        name="file"
                        value={file}
                        onChange={handleChange}
                        required
                    />
                </FormRow>
            </Form>
        </Modal>
    );
};

interface UploadUsersCSVModalProps {
    closeModal: VoidFunction;
    showUploadFailuresModal: VoidFunction;
    showSuccessModal: VoidFunction;
}

export default UploadUsersCSVModal;
