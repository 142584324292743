import { Config } from '.';

const config: Config = {
    API_URL: '', // todo
    OAUTH_URL: '', // todo
    OAUTH_CLIENT_ID: '', // todo
    OAUTH_REDIRECT_URL: '', // todo
    OAUTH_ACCESS_TOKEN_URL: '', // todo,
    OAUTH_SCOPE: 'openid profile email',
};

export default config;
