import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getLeadersIsPosting, getLeadersSearch, getLeadersSearchError } from '@selectors/leaders';
import useForm from 'lib/src/hooks/useForm';
import { searchLeaders } from '@actions/leaders';
import { api } from 'lib/src/utils/api';

const initialFormData: FormState = {
    firstName: '',
    lastName: '',
    entityLink: '',
    includeSubEntities: true,
    latestAssessmentDateFrom: null,
    latestAssessmentDateTo: null,
    selfScoreFrom: null,
    selfScoreTo: null,
    peerScoreFrom: null,
    peerScoreTo: null,
};

const useLeadersSearch = () => {
    const dispatch = useDispatch();

    const isPosting = useSelector(getLeadersIsPosting);
    const leaders = useSelector(getLeadersSearch);
    const error = useSelector(getLeadersSearchError);

    const [formState, handleChange] = useForm(initialFormData);
    const [tableWasSearched, setTableWasSearched] = useState(false);

    const handleSubmit = useCallback(() => {
        dispatch(searchLeaders(formState));
        setTableWasSearched(true);
    }, [dispatch, formState]);

    const handleDownloadCSV = () => {
        api.post<typeof formState, string>('/users/search/export', formState).then(({ data }) => {
            const el = document.createElement('a');
            el.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(data));
            el.setAttribute('download', 'leaders.csv');
            el.style.display = 'none';
            document.body.appendChild(el);
            el.click();
            document.body.removeChild(el);
        });
    };

    return {
        isPosting,
        error,
        formState,
        handleChange,
        handleSubmit,
        handleDownloadCSV,
        leaders,
        tableWasSearched,
    };
};

interface FormState {
    firstName: string;
    lastName: string;
    entityLink: string;
    includeSubEntities: boolean;
    latestAssessmentDateFrom: Date | null;
    latestAssessmentDateTo: Date | null;
    selfScoreFrom: number | null;
    selfScoreTo: number | null;
    peerScoreFrom: number | null;
    peerScoreTo: number | null;
}

export default useLeadersSearch;
