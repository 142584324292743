import { useDispatch, useSelector } from 'react-redux';
import { clearFormError, showFieldErrors } from '../../redux/actions/fieldErrors';
import { getFieldErrors, getFormError } from '../../redux/selectors/fieldErrors';
import ActionButton from '../button/ActionButton';
import ButtonRow from '../button/ButtonRow';
import { isEmpty } from '../../utils/generic';
import useDarkMode from '../../hooks/useDarkMode';
import { ReactNode, useEffect } from 'react';

const Form: React.FC<FormProps> = ({
    children,
    onSubmit,
    onCancel = () => {},
    omitButtons = false,
    omitCancelButton = false,
    isPosting = false,
    error = null,
    buttonAlignment = 'right',
    submitText = 'Submit',
}) => {
    const dispatch = useDispatch();
    const fieldErrors = useSelector(getFieldErrors);
    const formError = useSelector(getFormError) as ReactNode | null;
    const [darkMode] = useDarkMode();
    useEffect(() => {
        return () => {
            dispatch(clearFormError());
        };
    }, [dispatch]);

    return (
        <form onSubmit={_handleSubmit}>
            {children}
            {!!formError && <p className="form-generic-error">{formError}</p>}
            {!!error && (
                <p className={`form-generic-error ${darkMode ? 'dark-mode' : ''} `}>{error}</p>
            )}
            {!omitButtons && (
                <ButtonRow alignment={buttonAlignment}>
                    {!omitCancelButton && (
                        <ActionButton
                            source="secondary"
                            type="button"
                            onClick={_handleCancel}
                            disabled={isPosting}
                        >
                            Cancel
                        </ActionButton>
                    )}
                    <ActionButton isPosting={isPosting}>{submitText}</ActionButton>
                </ButtonRow>
            )}
        </form>
    );

    function _handleCancel(e: React.MouseEvent) {
        e.preventDefault();

        if (!isPosting) onCancel();
    }

    function _handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        if (!isEmpty(fieldErrors)) {
            dispatch(showFieldErrors());
        } else if (!isPosting) {
            dispatch(clearFormError());
            onSubmit();
        }
    }
};

export interface FormProps {
    children?: React.ReactNode;
    isPosting?: boolean;
    omitButtons?: boolean;
    omitCancelButton?: boolean;
    error?: string | null;
    onSubmit: () => void;
    onCancel?: () => void;
    buttonAlignment?: 'left' | 'right' | 'center';
    submitText?: string;
}

export default Form;
