import { BarProps, ReferenceLineProps } from 'lib/src/components/charts/ComposedChart';
import { isEmpty } from 'lib/src/utils/generic';
import { LeaderResults, PeerResult } from 'src/types/shared/Assessment';
import { Topic, TopicsBehaviour } from 'src/types/shared/TopicsBehaviors';

const useBarsChart = (
    latestSelfAssessment: LeaderResults,
    relevantPeerAssessments: PeerResult[],
    topicsBehaviors: TopicsBehaviour,
) => {
    const peerAssessmentData = relevantPeerAssessments.reduce(
        (acc: Record<string, string | number>[], assessment, index) => {
            if (isEmpty(assessment.topics)) return acc;

            const result: Record<string, string | number> = assessment.topics.reduce(
                (acc, curr) => {
                    const specificTopic = topicsBehaviors.topics.find(item => item.id === curr.id);
                    const objProperty: string = specificTopic?.name || 'topic';
                    return { ...acc, [objProperty]: curr.percentage || 0 };
                },
                {
                    name: `Peer ${index + 1} Assessment`,
                    Overall: assessment?.percentage || 0,
                },
            );
            return [...acc, result];
        },
        [],
    );

    const data = [
        latestSelfAssessment.topics.reduce(
            (acc, curr) => {
                const specificTopic = topicsBehaviors.topics.find(item => item.id === curr.id);
                const objProperty: string = specificTopic?.name || 'topic';
                return { ...acc, [objProperty]: curr.percentage || 0 };
            },
            {
                name: 'Self Assessment',
                Overall: latestSelfAssessment?.percentage,
            },
        ),
        ...peerAssessmentData,
    ];

    const referenceLines: ReferenceLineProps[] = [
        {
            value: 95,
            stroke: 'green',
        },
    ];

    const bars: BarProps[] = topicsBehaviors.topics.reduce(
        (acc: BarProps[], curr: Topic) => {
            return [
                ...acc,
                {
                    dataKey: curr.name,
                    fill: curr.colour || 'var( --other)',
                },
            ];
        },
        [
            {
                dataKey: 'Overall',
                fill: 'var( --overall)',
            },
        ],
    );

    return { data, bars, referenceLines };
};

export default useBarsChart;
