import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { Entity, EntityCreateRequest } from 'src/types/shared/Entity';

export const createEntityRequest = createAction('createEntityRequest');
export const createEntitySuccess = createAction<Entity>('createEntitySuccess');
export const createEntityFailure = createAction('createEntityFailure');

export const createEntity =
    (postBody: EntityCreateRequest) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(createEntityRequest());
        try {
            const { data } = await api.post<EntityCreateRequest, Entity>('entities', postBody);
            dispatch(createEntitySuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, createEntityFailure, e as APIError);
        }
    };
