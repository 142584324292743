import {
    fetchEntityAssessmentsFailure,
    fetchEntityAssessmentsRequest,
    fetchEntityAssessmentsSuccess,
} from '@actions/entityAssessments';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { EntityAssessment } from 'src/types/shared/Entity';

interface EntityAssessmentsState {
    isFetching: boolean;
    fetchError: string | null;
    entityAssessments: EntityAssessment[] | null;
}

const initialState: EntityAssessmentsState = {
    isFetching: false,
    fetchError: null,
    entityAssessments: null,
};

export default createReducer(initialState, {
    [fetchEntityAssessmentsRequest.type]: handleFetchRequest,
    [fetchEntityAssessmentsSuccess.type]: handleFetchEntityAssessmentsSuccess,
    [fetchEntityAssessmentsFailure.type]: handleFetchError,
});

function handleFetchRequest(state: EntityAssessmentsState) {
    state.isFetching = true;
    state.fetchError = null;
}

function handleFetchError(state: EntityAssessmentsState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.fetchError = action.payload;
}

function handleFetchEntityAssessmentsSuccess(
    state: EntityAssessmentsState,
    action: PayloadAction<EntityAssessment[]>,
) {
    state.isFetching = false;
    state.entityAssessments = action.payload;
}
