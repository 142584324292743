import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { Entity, EntityEditRequest } from 'src/types/shared/Entity';

export const editEntityRequest = createAction('editEntityRequest');
export const editEntitySuccess = createAction<Entity>('editEntitySuccess');
export const editEntityFailure = createAction('editEntityFailure');

export const editEntity =
    (id: number, postBody: EntityEditRequest) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(editEntityRequest());
        try {
            const { data } = await api.patch<EntityEditRequest, Entity>(`entities/${id}`, postBody);
            dispatch(editEntitySuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, editEntityFailure, e as APIError);
        }
    };
