import useBehaviourDataPointsForCompareChart from '@hooks/compare/useBehaviourDataPointsForCompareChart';
import useTopicsAndBehavioursForCompareChart from '@hooks/compare/useTopicsAndBehavioursForCompareChart';
import useTopicStatsForCompareChart from '@hooks/compare/useTopicStatsForCompareChart';
import AssessmentChart from 'lib/src/components/charts/AssessmentChart';
import { AnchorResult, ComparisonResult } from 'src/types/shared/Comparison';
import { TopicsBehaviour } from 'src/types/shared/TopicsBehaviors';

const ComparisonChart: React.FC<ComparisonChartProps> = ({
    topicsBehaviours,
    anchor,
    comparison,
    leader,
}) => {
    const { topicsForAssessmentChart, behavioursForAssessmentChart } =
        useTopicsAndBehavioursForCompareChart(topicsBehaviours, anchor, comparison);
    const behaviourDataPointsForCompareChart = useBehaviourDataPointsForCompareChart(
        topicsBehaviours,
        topicsForAssessmentChart,
        comparison,
        leader,
    );
    const topicStatsForCompareChart = useTopicStatsForCompareChart(
        topicsForAssessmentChart,
        anchor,
        leader,
    );

    return (
        <AssessmentChart
            topics={topicsForAssessmentChart}
            behaviours={behavioursForAssessmentChart}
            dataPoints={behaviourDataPointsForCompareChart}
            chartStats={topicStatsForCompareChart}
        />
    );
};

interface ComparisonChartProps {
    topicsBehaviours: TopicsBehaviour;
    anchor: AnchorResult;
    comparison: ComparisonResult[];
    leader: AnchorResult | null;
}

export default ComparisonChart;
