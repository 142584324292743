import React, { ReactNode } from 'react';

import { entityTypeNames } from 'lib/src/shared/enums/entityEnums';
import { isEmpty } from 'lib/src/utils/generic';
import { Entity } from 'src/types/shared/Entity';

import useFetchEntities from './hooks/useFetchEntities';

import LinkButton from 'lib/src/components/button/LinkButton';
import ButtonRow from 'lib/src/components/button/ButtonRow';

const entityTableHeaders = [
    'Name',
    'Code',
    'Type',
    'Leaders Assigned / Nested',
    'HR Assigned / Nested',
    '',
];

const EntitiesTable: React.FC = () => {
    const { entitiesArr, isFetching, fetchError } = useFetchEntities();

    const renderHierarchy = (entity: Entity, padding: number): ReactNode => (
        <React.Fragment key={entity.id}>
            <tr>
                <td style={{ paddingLeft: padding }}>{entity.name}</td>
                <td>{entity.code}</td>
                <td>{entityTypeNames[entity.type]}</td>
                <td>{`${entity.leaderCount} / ${entity.nestedLeaderCount}`}</td>
                <td>{`${entity.hrCount} / ${entity.nestedHRCount}`}</td>
                <td>
                    <ButtonRow alignment="right">
                        <LinkButton href={`/entities/${entity.id}`}>View</LinkButton>
                    </ButtonRow>
                </td>
            </tr>
            {!isEmpty(entity.children) &&
                entity.children.map(ent => renderHierarchy(ent, padding + 15))}
        </React.Fragment>
    );

    // not using table component due to nesting
    return (
        <table>
            <thead>
                <tr>
                    {entityTableHeaders.map(header => (
                        <th key={header}>{header}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {isFetching && isEmpty(entitiesArr) && (
                    <tr>
                        <td className="loading-row" colSpan={entityTableHeaders.length}>
                            <i className="fal fa-spinner fa-spin"></i>
                        </td>
                    </tr>
                )}
                {fetchError && (
                    <tr className="no-data-row">
                        <td colSpan={entityTableHeaders.length}>{fetchError}</td>
                    </tr>
                )}
                {isEmpty(entitiesArr) && !isFetching && !fetchError && (
                    <tr className="no-data-row">
                        <td colSpan={entityTableHeaders.length}>
                            There are no entities to display.
                        </td>
                    </tr>
                )}
                {entitiesArr.map(entity => renderHierarchy(entity, 15))}
            </tbody>
        </table>
    );
};

export default EntitiesTable;
