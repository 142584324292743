import React from 'react';
import { useSelector } from 'react-redux';

import { Entity } from 'src/types/shared/Entity';
import { UserWithEntityName } from 'src/types/shared/User';

import { getEntitiesSimple } from '@selectors/entities';

import useGetEntitiesAndNested from '@pages/entities/entities/hooks/useGetEntitiesAndNested';

import Table from 'lib/src/components/table/Table';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import LinkButton from 'lib/src/components/button/LinkButton';

const EntityNestedHRStaff: React.FC<EntityNestedHRStaffProps> = ({ entity, isAdmin }) => {
    const entitiesSimple = useSelector(getEntitiesSimple);
    const entitiesWithNested = useGetEntitiesAndNested([entity]);
    const nestedHRs = entitiesWithNested
        .filter(ent => ent.id !== entity.id)
        .map(ent => ent.hrs)
        .flat()
        .map(hr => ({
            ...hr,
            entityName:
                hr.entityID && entitiesSimple[hr.entityID] ? entitiesSimple[hr.entityID].name : '',
        }));

    const columns = [
        {
            key: 1,
            heading: 'UUID',
            getValue: (row: UserWithEntityName) => row.uuid,
        },
        {
            key: 2,
            heading: 'First name',
            getValue: (row: UserWithEntityName) => row.firstName,
        },
        {
            key: 3,
            heading: 'Last name',
            getValue: (row: UserWithEntityName) => row.lastName,
        },
        {
            key: 4,
            heading: 'Entity',
            getValue: (row: UserWithEntityName) => row.entityName,
        },
        {
            key: 5,
            heading: '',
            getValue: (row: UserWithEntityName) =>
                isAdmin ? (
                    <ButtonRow alignment="right">
                        <LinkButton href={`/users/${row.id}`}>View</LinkButton>
                    </ButtonRow>
                ) : null,
        },
    ];

    return <Table columns={columns} rows={nestedHRs} />;
};

interface EntityNestedHRStaffProps {
    entity: Entity;
    isAdmin: boolean;
}

export default EntityNestedHRStaff;
