import React from 'react';

import FlexWrapper from 'lib/src/components/layout/wrapper/FlexWrapper';
import { TopicsBehaviour } from 'src/types/shared/TopicsBehaviors';
import useFetchEntityAssessments from '../hooks/useFetchEntityAssessments';

const LatestPeerAssessment: React.FC<LatestPeerAssessmentProps> = ({
    userID,
    entityID,
    topicsBehaviors,
}) => {
    const { entityAssessments } = useFetchEntityAssessments(entityID);

    const peerResults =
        entityAssessments?.find(item => item.userID === userID)?.assessmentResults.peerResults ||
        [];

    const latestAssessment = peerResults
        .concat([])
        ?.sort((a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime())[0];

    return (
        <FlexWrapper>
            {latestAssessment && (
                <div
                    className="assessmentResult percentageResult"
                    style={{ backgroundColor: 'var( --overall)', color: 'white' }}
                >
                    {latestAssessment?.percentage}
                </div>
            )}
            {latestAssessment && latestAssessment.topics
                ? latestAssessment?.topics.map(topic => (
                      <div
                          key={topic.id}
                          className="assessmentResult percentageResult"
                          style={{
                              backgroundColor: `${
                                  topicsBehaviors.topics.find(item => item.id === topic?.id)?.colour
                              }`,
                              color: 'white',
                          }}
                      >
                          {topic.percentage}
                      </div>
                  ))
                : '-'}
        </FlexWrapper>
    );
};

interface LatestPeerAssessmentProps {
    userID: number;
    entityID: number;
    topicsBehaviors: TopicsBehaviour;
}

export default LatestPeerAssessment;
