import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { fetchSingleUser } from '@actions/users/fetchSingleUser';
import { selectUser, selectUsersError, selectUsersIsFetching } from '@selectors/users';
import { RootState } from '@reducers/index';
import UserContent from './UserContent';
import DataCheck from 'lib/src/components/layout/dataCheck/DataCheck';
import useFetchUserAssessment from './hooks/useFetchUserAssessment';

const UserContainer: React.FC = () => {
    const { id } = useParams<ParamTypes>();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchSingleUser(+id));
    }, [id, dispatch]);

    const user = useSelector((state: RootState) => selectUser(state, +id));
    const isFetching = useSelector(selectUsersIsFetching);
    const fetchError = useSelector(selectUsersError);

    const {
        assessments,
        isFetching: isFetchingAssessments,
        fetchError: assessmentsFetchError,
    } = useFetchUserAssessment(+id);

    return (
        <DataCheck
            isEmpty={!user || user.id !== +id || !assessments}
            isLoading={isFetching || isFetchingAssessments}
            error={fetchError || assessmentsFetchError}
        >
            {user && assessments && <UserContent user={user} assessments={assessments} />}
        </DataCheck>
    );
};

interface ParamTypes {
    id: string;
}

export default UserContainer;
