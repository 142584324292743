import { RootState } from '@reducers/index';

import { TopicsBehaviour } from 'src/types/shared/TopicsBehaviors';

export const getAllTopicsBehaviors = (state: RootState): TopicsBehaviour =>
    state.topicsBehaviorsReducer.topicsBehaviors;
export const geTopicsBehaviorsIsFetching = (state: RootState): boolean =>
    state.topicsBehaviorsReducer.isFetching;
export const geTopicsBehaviorsFetchError = (state: RootState): string | null =>
    state.topicsBehaviorsReducer.fetchError;
