import { DropdownOption } from '../../types/shared/DropdownOption';
import { userRoles } from './userEnums';

export const reverseEnum = (baseEnum: { [key: string]: number }): { [key: number]: string } => {
    const reversed: { [key: number]: string } = {};
    Object.entries(baseEnum).forEach(([key, value]) => {
        reversed[value] = key;
    });
    return reversed;
};

export function enumKeys<O extends Record<string, unknown>, K extends keyof O = keyof O>(
    obj: O,
): K[] {
    return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
}

export enum adminUserRoles {
    superAdmin = 1000,
    admin = 500,
}

export const adminUserRoleNames: { [key: number]: string | undefined | null } = {
    [adminUserRoles.superAdmin]: 'Super Admin',
};
interface SuperAdminRolesEnumModel {
    superAdmin: number;
    admin: number;
}
interface UserDropdownEnumModel {
    administrator: number;
    hr: number;
    leader: number;
}

export const convertEnumToDropdownOption = (
    enumType: Record<string, string>,
): DropdownOption<number>[] => {
    const values: DropdownOption<number>[] = Object.keys(enumType).map(key => ({
        value: +key,
        label: enumType[key],
    }));
    return values;
};
