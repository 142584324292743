import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Entity } from 'src/types/shared/Entity';

import { createEntity } from '@actions/entities';
import {
    getEntitiesIsPosting,
    getEntitiesPostError,
    getEntitiesPostSuccess,
    getNewlyAddedEntityID,
} from '@selectors/entities';

import useForm from 'lib/src/hooks/useForm';
import usePrevious from 'lib/src/hooks/usePrevious';

const useCreateChildEntity = (entity: Entity, closeModal: VoidFunction) => {
    const dispatch = useDispatch();
    const isPosting = useSelector(getEntitiesIsPosting);
    const postError = useSelector(getEntitiesPostError);
    const postSuccess = useSelector(getEntitiesPostSuccess);
    const newlyAddedEntityID = useSelector(getNewlyAddedEntityID);
    const history = useHistory();

    const prevProps = usePrevious({ postError, postSuccess, newlyAddedEntityID });

    const [form, handleChange] = useForm({
        name: '',
        code: '',
        type: null,
    });

    const handleSubmit = () => {
        const postBody = {
            ...form,
            parentEntityID: entity.id,
        };
        dispatch(createEntity(postBody));
    };

    useEffect(() => {
        if ((postError && !prevProps.postError) || (postSuccess && !prevProps.postSuccess)) {
            closeModal();
        }
    }, [postError, prevProps.postError, postSuccess, prevProps.postSuccess, closeModal]);

    useEffect(() => {
        if (newlyAddedEntityID && !prevProps.newlyAddedEntityID) {
            history.push(`/entities/${newlyAddedEntityID}`);
        }
    }, [history, newlyAddedEntityID, prevProps.newlyAddedEntityID]);

    return { form, handleChange, handleSubmit, isPosting };
};

export default useCreateChildEntity;
