import ActionButton from '../button/ActionButton';
import ButtonRow from '../button/ButtonRow';
import Description from '../typography/Description';
import Modal from './Modal';

const ErrorModal: React.FC<ErrorModalProps> = ({
    closeModal,
    title = 'Error',
    description = 'There was an error.',
}) => {
    return (
        <Modal title={title} size="small">
            <Description>{description}</Description>
            <ButtonRow alignment="right">
                <ActionButton onClick={closeModal}>Close</ActionButton>
            </ButtonRow>
        </Modal>
    );
};
interface ErrorModalProps {
    closeModal: VoidFunction;
    title?: string;
    description?: string;
}

export default ErrorModal;
