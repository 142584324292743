import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { TopicsBehaviour } from 'src/types/shared/TopicsBehaviors';

export const fetchTopicsBehaviorsRequest = createAction('fetchTopicsBehaviorsRequest');
export const fetchTopicsBehaviorsSuccess = createAction<TopicsBehaviour>(
    'fetchTopicsBehaviorsSuccess',
);
export const fetchTopicsBehaviorsFailure = createAction('fetchTopicsBehaviorsFailure');

export const fetchAllTopicsBehaviors =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchTopicsBehaviorsRequest());
        try {
            const { data } = await api.get<TopicsBehaviour>('assessment/topicsAndBehaviours');
            dispatch(fetchTopicsBehaviorsSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchTopicsBehaviorsFailure, e as APIError);
        }
    };
