import * as React from 'react';

const CreateHeader: React.FC<ContentHeaderProps> = ({ children, extraClasses = '' }) => (
    <div className={`create-header ${extraClasses}`}>{children}</div>
);

interface ContentHeaderProps {
    children: React.ReactNode;
    extraClasses?: string;
}

export default CreateHeader;
