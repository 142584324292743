import { compareLeaders } from '@actions/leaders/compareLeaders';
import {
    getAnchorLeader,
    getComparisonLeaders,
    getLeader,
    getLeadersComparisonError,
    getLeadersComparisonIsFetching,
} from '@selectors/leaders';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ComparisonParams } from 'src/types/shared/Comparison';

const useLeadersComparisonFetch = () => {
    const dispatch = useDispatch();
    const [searched, setSearched] = useState(false);
    const searchParams = useLocation().search;
    const params: ParamsProps = qs.parse(searchParams, { ignoreQueryPrefix: true });
    const ids = params.ids;
    const leaderID = params.leaderID;

    const anchorLeader = useSelector(getAnchorLeader);
    const comparisonLeaders = useSelector(getComparisonLeaders);
    const leader = useSelector(getLeader);
    const isFetching = useSelector(getLeadersComparisonIsFetching);
    const error = useSelector(getLeadersComparisonError);

    useEffect(() => {
        if (!!ids && Array.isArray(ids) && ids.length > 1 && !searched) {
            const apiParams: ComparisonParams = {
                anchorId: +ids[0],
                comparisonIds: ids.slice(1).map(id => +id),
            };

            if (leaderID) {
                apiParams.leaderId = +leaderID;
            }

            dispatch(compareLeaders(apiParams));
            setSearched(true);
        }
    }, [dispatch, params, ids, searched, leaderID]);

    return { anchorLeader, comparisonLeaders, leader, isFetching, error };
};

interface ParamsProps {
    ids?: string | string[];
    leaderID?: string;
}

export default useLeadersComparisonFetch;
