import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useForm from 'lib/src/hooks/useForm';
import usePrevious from 'lib/src/hooks/usePrevious';
import {
    selectUsersFailedUploadUsers,
    selectUsersIsPosting,
    selectUsersPostError,
    selectUsersPostSuccess,
} from '@selectors/users';
import { fetchAllUsers, postUploadUsersCSV } from '@actions/users';

const initialFormData: FormState = {
    file: null,
};

export default function useUploadUsersCSV(
    closeModal: VoidFunction,
    showUploadFailuresModal: VoidFunction,
    showSuccessModal: VoidFunction,
) {
    const dispatch = useDispatch();

    const isPosting = useSelector(selectUsersIsPosting);
    const postSuccess = useSelector(selectUsersPostSuccess);
    const error = useSelector(selectUsersPostError);
    const failedUsers = useSelector(selectUsersFailedUploadUsers);

    const [formState, handleChange] = useForm(initialFormData);

    const handleSubmit = useCallback(() => {
        dispatch(postUploadUsersCSV(formState.file as File));
    }, [dispatch, formState]);

    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            dispatch(fetchAllUsers());
            closeModal();

            if (Object.keys(failedUsers).length) {
                showUploadFailuresModal();
            } else {
                showSuccessModal();
            }
        }
    }, [
        dispatch,
        prevPostSuccess,
        postSuccess,
        showUploadFailuresModal,
        closeModal,
        failedUsers,
        showSuccessModal,
    ]);

    return {
        isPosting,
        error,
        formState,
        handleChange,
        handleSubmit,
        postSuccess,
        failedUsers: Object.values(failedUsers),
    };
}

interface FormState {
    file: File | null;
}
