import React from 'react';
import { useSelector } from 'react-redux';

import { Entity } from 'src/types/shared/Entity';
import { UserWithEntityName } from 'src/types/shared/User';
import { getEntitiesSimple } from '@selectors/entities';
import useGetEntitiesAndNested from '@pages/entities/entities/hooks/useGetEntitiesAndNested';
import Table from 'lib/src/components/table/Table';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import LinkButton from 'lib/src/components/button/LinkButton';
import LatestSelfAssessment from './LatestSelfAssessment';
import LatestPeerAssessment from './LatestPeerAssessment';
import useFetchTopicsBehaviors from '../hooks/useFetchTopicsBehaviors';

const EntityNestedLeaders: React.FC<EntityNestedLeadersProps> = ({ entity, isAdmin }) => {
    const entitiesSimple = useSelector(getEntitiesSimple);
    const entitiesWithNested = useGetEntitiesAndNested([entity]);
    const { topicsBehaviors } = useFetchTopicsBehaviors();

    const nestedLeaders = entitiesWithNested
        .filter(ent => ent.id !== entity.id)
        .map(ent => ent.leaders)
        .flat()
        .map(leader => ({
            ...leader,
            entityName:
                leader.entityID && entitiesSimple[leader.entityID]
                    ? entitiesSimple[leader.entityID].name
                    : '',
        }));

    const columns = [
        {
            key: 1,
            heading: 'UUID',
            getValue: (row: UserWithEntityName) => row.uuid,
        },
        {
            key: 2,
            heading: 'First name',
            getValue: (row: UserWithEntityName) => row.firstName,
        },
        {
            key: 3,
            heading: 'Last name',
            getValue: (row: UserWithEntityName) => row.lastName,
        },
        {
            key: 4,
            heading: 'Entity',
            getValue: (row: UserWithEntityName) => row.entityName,
        },
        {
            key: 5,
            heading: 'Latest self assessment',
            getValue: (row: UserWithEntityName) => (
                <LatestSelfAssessment
                    userID={row.id}
                    entityID={entity.id}
                    topicsBehaviors={topicsBehaviors}
                />
            ),
        },
        {
            key: 6,
            heading: 'Latest peer assessment',
            getValue: (row: UserWithEntityName) => (
                <LatestPeerAssessment
                    userID={row.id}
                    entityID={entity.id}
                    topicsBehaviors={topicsBehaviors}
                />
            ),
        },
        {
            key: 7,
            heading: '',
            getValue: (row: UserWithEntityName) =>
                isAdmin ? (
                    <ButtonRow alignment="right">
                        <LinkButton href={`/users/${row.id}`}>View</LinkButton>
                    </ButtonRow>
                ) : null,
        },
    ];
    return <Table columns={columns} rows={nestedLeaders} />;
};

interface EntityNestedLeadersProps {
    entity: Entity;
    isAdmin: boolean;
}

export default EntityNestedLeaders;
