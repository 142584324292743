import React, { useState } from 'react';
import ComposedChart from 'lib/src/components/charts/ComposedChart';
import AssessmentChart from 'lib/src/components/charts/AssessmentChart';
import { LeaderResults, PeerAverageResult, PeerResult } from 'src/types/shared/Assessment';
import { TopicsBehaviour } from 'src/types/shared/TopicsBehaviors';
import useBarsChart from './hooks/useBarsChart';
import useForm from 'lib/src/hooks/useForm';
import FormField from 'lib/src/components/form/FormField';
import Select from 'lib/src/components/form/Select';
import dayjs from 'dayjs';

import { DATE_FORMAT } from 'lib/src/constants/dateFormats';
import FlexWrapper from 'lib/src/components/layout/wrapper/FlexWrapper';
import useTopicsAndBehavioursForAssessmentChart from './hooks/useTopicsAndBehavioursForAssessmentChart';
import useBehaviourDataPointsForAssessmentChart from './hooks/useBehaviourDataPointsForAssessmentChart';

const AssessmentDetails: React.FC<AssessmentDetailsProps> = ({
    leaderResults,
    peerResults,
    peerAverageResults,
    topicsBehaviors,
}) => {
    const [chartView, setChartView] = useState<'bars' | 'radar'>('bars');
    const leaderResultsDesc = [...leaderResults].sort(
        (a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime(),
    );
    const latestSelfAssessment = leaderResultsDesc[0];

    const [form, handleChange] = useForm({
        selectedAssessmentID: latestSelfAssessment.assessmentID,
    });

    const selectedAssessment =
        leaderResults.find(res => res.assessmentID === form.selectedAssessmentID) ||
        latestSelfAssessment;

    const relevantPeerAssessments = peerResults.filter(
        peerResult => peerResult.parentAssessmentID === form.selectedAssessmentID,
    );

    const { data, bars, referenceLines } = useBarsChart(
        selectedAssessment,
        relevantPeerAssessments,
        topicsBehaviors,
    );

    const assessmentOptions = leaderResultsDesc.map(res => ({
        value: res.assessmentID,
        label: dayjs(res.createdOn).format(DATE_FORMAT),
    }));

    const { topicsForAssessmentChart, behavioursForAssessmentChart } =
        useTopicsAndBehavioursForAssessmentChart(
            topicsBehaviors,
            selectedAssessment,
            peerAverageResults,
        );

    const behaviourDataPointsForAssessmentChart = useBehaviourDataPointsForAssessmentChart(
        topicsBehaviors,
        selectedAssessment,
        peerAverageResults,
        topicsForAssessmentChart,
    );

    return (
        <>
            <FlexWrapper width={4} extraClasses="assessment-chart-dropdown" align="center">
                <FormField name="selectedAssessmentID" label="Select assessment">
                    <Select
                        name="selectedAssessmentID"
                        value={form.selectedAssessmentID}
                        options={assessmentOptions}
                        onChange={handleChange}
                        disableRemoveButton
                    />
                </FormField>

                <button
                    className="switch-chart-btn"
                    onClick={() => setChartView(chartView === 'bars' ? 'radar' : 'bars')}
                >
                    {chartView === 'bars' ? 'Radar' : 'Bars'}
                </button>
            </FlexWrapper>

            {chartView === 'bars' ? (
                <>
                    <div style={{ padding: '50px', height: 600 }}>
                        <ComposedChart
                            data={data}
                            bars={bars}
                            showTooltip
                            referenceLines={referenceLines}
                            showGrid={true}
                            ninetyFiveReferenceLabel={true}
                            scroll={true}
                            XDomain={[0, 100]}
                            YDomain={[0, 100]}
                            layout="vertical"
                            radius={[0, 50, 50, 0]}
                            YWidth={140}
                        />
                    </div>
                </>
            ) : (
                <>
                    <AssessmentChart
                        topics={topicsForAssessmentChart}
                        behaviours={behavioursForAssessmentChart}
                        dataPoints={behaviourDataPointsForAssessmentChart}
                        isSmallVersion
                        isAdminVersion
                    />
                    <button className="switch-chart-btn" onClick={() => setChartView('bars')}>
                        Bars
                    </button>
                </>
            )}
        </>
    );
};

interface AssessmentDetailsProps {
    leaderResults: LeaderResults[];
    peerResults: PeerResult[];
    peerAverageResults: PeerAverageResult[];
    topicsBehaviors: TopicsBehaviour;
}

export default AssessmentDetails;
