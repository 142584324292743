const TableSearch: React.FC<TableSearchProps> = ({
    onChange,
    value,
    searchBarLabel,
    placeholder = 'Search...',
}) => {
    return (
        <div className="table-search flex-column align-start">
            <label style={{ marginBottom: '10px' }}>{searchBarLabel}</label>
            <div className="flex-row align-center">
                <i className="icon fal fa-search"></i>
                <input
                    type="text"
                    onChange={handleChange}
                    value={value}
                    placeholder={placeholder}
                />
            </div>
        </div>
    );

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        onChange(e.target.value);
    }
};

export interface TableSearchProps {
    onChange: (value: string) => void;
    value: string;
    searchBarLabel?: string;
    placeholder?: string;
}

export default TableSearch;
