import React, { useState } from 'react';

import Title from 'lib/src/components/typography/Title';
import { userRoleNames } from 'lib/src/shared/enums/userEnums';
import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import { User } from 'src/types/shared/User';
import ColouredSquare from './ColouredSquare';
import Tabs, { Tab } from 'lib/src/components/tabs/Tabs';
import SelfAssessments from './SelfAssessments';
import PeerAssessments from './PeerAssessments';
import AssessmentDetails from './AssessmentDetails';
import Meta from 'lib/src/components/meta/Meta';
import useFetchEntities from '@pages/entities/entities/hooks/useFetchEntities';
import useFetchTopicsBehaviors from '@pages/entities/entity/hooks/useFetchTopicsBehaviors';
import { AllTypesOfAssessment } from 'src/types/shared/Assessment';
import { isEmpty } from 'lib/src/utils/generic';

const assessmentTabValues = { selfAssessments: 1, peerAssessments: 2, assessmentDetails: 3 };

const UserContent: React.FC<UserProps> = ({ user, assessments }) => {
    const { entitiesSimpleArr } = useFetchEntities();
    const { firstName, lastName, role, uuid, entityID } = user;
    const { topicsBehaviors } = useFetchTopicsBehaviors();

    const leaderResults = assessments.map(item => item.leaderResults);
    const peerResults = assessments.map(item => item.peerResults).flat();
    const peerAverageResults = assessments.map(item => item.peerAverageResults).flat();

    const assessmentsTabs: Tab[] = [
        {
            id: assessmentTabValues.selfAssessments,
            name: 'Self Assessments',
        },
        {
            id: assessmentTabValues.peerAssessments,
            name: 'Peer Assessments',
        },
    ];

    if (!isEmpty(leaderResults)) {
        assessmentsTabs.push({
            id: assessmentTabValues.assessmentDetails,
            name: 'Assessment Details',
        });
    }

    const [selectedTabID, setSelectedTabID] = useState(assessmentsTabs[0].id);

    return (
        <>
            <Meta title={`${firstName} ${lastName}`} />

            <Title>
                Users/ {firstName} {lastName}
            </Title>

            <div className="flex-row">
                <div className="flex-column" style={{ marginRight: '10px', minWidth: '200px' }}>
                    <ContentBlock>
                        <p className="page-subtitle">User Information</p>

                        <p>{`UUID: ${uuid}`}</p>
                        <p>{`Type: ${userRoleNames[role]}`}</p>
                        <p>{`Attached To: ${
                            entityID
                                ? entitiesSimpleArr.find(entity => entity.id === entityID)?.name
                                : '-'
                        }`}</p>
                    </ContentBlock>
                    <ContentBlock>
                        <p className="page-subtitle">Colour Key</p>
                        <div className="flex-row align-center">
                            <ColouredSquare colour="var( --overall)" />
                            <p style={{ marginLeft: '10px' }}>Overall</p>
                        </div>
                        {topicsBehaviors.topics.map(item => (
                            <div key={item.id} className="flex-row align-center">
                                <ColouredSquare colour={`${item.colour}`} />
                                <p style={{ marginLeft: '10px' }}>{item.name}</p>
                            </div>
                        ))}
                        <div className="flex-row align-center">
                            <ColouredSquare colour="var( --other)" />
                            <p style={{ marginLeft: '10px' }}>Other</p>
                        </div>
                    </ContentBlock>
                </div>

                <div className="flex-9">
                    <ContentBlock extraStyle={{ minHeight: '390px' }}>
                        <Tabs
                            tabs={assessmentsTabs}
                            selectedTabID={selectedTabID}
                            setSelectedTabID={setSelectedTabID}
                        />

                        {selectedTabID === assessmentTabValues.selfAssessments ? (
                            <SelfAssessments
                                leaderResults={leaderResults}
                                topicsBehaviors={topicsBehaviors}
                            />
                        ) : selectedTabID === assessmentTabValues.peerAssessments ? (
                            <PeerAssessments
                                peerResults={peerResults}
                                topicsBehaviors={topicsBehaviors}
                            />
                        ) : selectedTabID === assessmentTabValues.assessmentDetails ? (
                            <AssessmentDetails
                                leaderResults={leaderResults}
                                peerResults={peerResults}
                                peerAverageResults={peerAverageResults}
                                topicsBehaviors={topicsBehaviors}
                            />
                        ) : null}
                    </ContentBlock>
                </div>
            </div>
        </>
    );
};

interface UserProps {
    user: User;
    assessments: AllTypesOfAssessment[];
}
export default UserContent;
