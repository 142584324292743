import React from 'react';

import useJobRoleSearch from './hooks/useJobRoleSearch';
import useJobRolesSelection from './hooks/useJobRolesSelection';

import CreateHeader from '@components/layout/createHeader/CreateHeader';
import Meta from 'lib/src/components/meta/Meta';
import Title from 'lib/src/components/typography/Title';
import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import TextInput from 'lib/src/components/form/TextInput';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import ActionButton from 'lib/src/components/button/ActionButton';
import JobRolesTable from './JobRolesTable';
import LinkButton from 'lib/src/components/button/LinkButton';
import Form from 'lib/src/components/form/Form';

const JobRoleSearch: React.FC = () => {
    const { isPosting, jobRoles, error, formState, handleChange, handleSubmit } =
        useJobRoleSearch();

    const { selected, isSelected, updateSelection, isMaxedOut, comparisonLink } =
        useJobRolesSelection();

    return (
        <>
            <Meta title="Job Role Search" />

            <CreateHeader>
                <Title>Job Role Search</Title>
            </CreateHeader>

            <Form onSubmit={handleSubmit} omitButtons>
                <ContentBlock>
                    <div className="flex-row">
                        <div className="flex-column flex-5">
                            <TextInput
                                name="searchTerm"
                                value={formState.searchTerm}
                                onChange={handleChange}
                                label="Job Role"
                            />
                        </div>
                    </div>
                    <div className="flex-row">
                        <div className="flex-5 justify-end">
                            <ButtonRow alignment="right">
                                <ActionButton
                                    type="submit"
                                    source="positive"
                                    isPosting={isPosting}
                                    disabled={isPosting || !formState.searchTerm.length}
                                >
                                    Search
                                </ActionButton>
                            </ButtonRow>
                        </div>
                    </div>
                </ContentBlock>
            </Form>

            {!!selected.length && (
                <>
                    <CreateHeader extraClasses="top-margin">
                        <Title>Selected Job Roles</Title>
                        {selected.length > 1 && (
                            <LinkButton href={comparisonLink} openInNewTab>
                                Compare
                            </LinkButton>
                        )}
                    </CreateHeader>

                    <ContentBlock>
                        <ButtonRow alignment="left">
                            {selected.map(jobRole => (
                                <ActionButton
                                    key={jobRole.id}
                                    source="negative"
                                    icon="times"
                                    onClick={() => updateSelection(jobRole)}
                                >
                                    {jobRole.name}
                                </ActionButton>
                            ))}
                        </ButtonRow>
                    </ContentBlock>
                </>
            )}

            {
                <>
                    <CreateHeader extraClasses="top-margin">
                        <Title>Results</Title>
                    </CreateHeader>
                    <JobRolesTable
                        jobRoles={jobRoles}
                        isFetching={isPosting}
                        error={error}
                        isSelected={isSelected}
                        updateSelection={updateSelection}
                        isMaxedOut={isMaxedOut}
                    />
                </>
            }
        </>
    );
};

export default JobRoleSearch;
