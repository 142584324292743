import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { convertArrToObj } from 'lib/src/utils/generic';
import {
    fetchEntitiesRequest,
    fetchEntitiesSuccess,
    fetchEntitiesFailure,
    fetchEntitiesSimpleSuccess,
    fetchEntityRequest,
    fetchEntitySuccess,
    fetchEntityFailure,
    createEntityRequest,
    createEntitySuccess,
    createEntityFailure,
    editEntityRequest,
    editEntitySuccess,
    editEntityFailure,
} from '@actions/entities';
import { Entity, EntitySimple } from 'src/types/shared/Entity';

interface EntitiesState {
    isFetching: boolean;
    isPosting: boolean;
    fetchError: string | null;
    postError: string | null;
    postSuccess: boolean;
    entities: Record<number, Entity>;
    entitiesSimple: Record<number, EntitySimple>;
    entity: Entity | null;
    newlyAddedEntityID: number | null;
}

const initialState: EntitiesState = {
    isFetching: false,
    isPosting: false,
    fetchError: null,
    postError: null,
    postSuccess: false,
    entities: {},
    entitiesSimple: {},
    entity: null,
    newlyAddedEntityID: null,
};

export default createReducer(initialState, {
    [fetchEntitiesRequest.type]: handleFetchRequest,
    [fetchEntitiesSuccess.type]: handleFetchEntitiesSuccess,
    [fetchEntitiesFailure.type]: handleFetchError,
    [fetchEntitiesSimpleSuccess.type]: handleFetchEntitiesSimpleSuccess,
    [fetchEntityRequest.type]: handleFetchRequest,
    [fetchEntitySuccess.type]: handleFetchEntitySuccess,
    [fetchEntityFailure.type]: handleFetchError,
    [createEntityRequest.type]: handlePostRequest,
    [createEntitySuccess.type]: handleCreateEntitySuccess,
    [createEntityFailure.type]: handlePostError,
    [editEntityRequest.type]: handlePostRequest,
    [editEntitySuccess.type]: handleEditEntitySuccess,
    [editEntityFailure.type]: handlePostError,
});

function handleFetchRequest(state: EntitiesState) {
    state.isFetching = true;
    state.fetchError = null;
}

function handlePostRequest(state: EntitiesState) {
    state.isPosting = true;
    state.postError = null;
    state.postSuccess = false;
    state.newlyAddedEntityID = null;
}

function handleFetchError(state: EntitiesState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.fetchError = action.payload;
}

function handlePostError(state: EntitiesState, action: PayloadAction<string>) {
    state.isPosting = false;
    state.postError = action.payload;
}

function handleCreateEntitySuccess(state: EntitiesState, action: PayloadAction<Entity>) {
    state.isPosting = false;
    state.postSuccess = true;
    state.newlyAddedEntityID = action.payload.id;
}

function handleEditEntitySuccess(state: EntitiesState, action: PayloadAction<Entity>) {
    state.isPosting = false;
    state.postSuccess = true;
    state.entity = action.payload;
}

function handleFetchEntitiesSuccess(state: EntitiesState, action: PayloadAction<Entity[]>) {
    state.isFetching = false;
    state.entities = convertArrToObj(action.payload);
}

function handleFetchEntitiesSimpleSuccess(
    state: EntitiesState,
    action: PayloadAction<EntitySimple[]>,
) {
    state.isFetching = false;
    state.entitiesSimple = convertArrToObj(action.payload);
}

function handleFetchEntitySuccess(state: EntitiesState, action: PayloadAction<Entity>) {
    state.isFetching = false;
    state.entity = action.payload;
}
