import { SyntheticEvent, useCallback } from 'react';

import useFieldValidation from '../../hooks/useFieldValidation';
import { onChangeFunction } from '../../types/shared/FormInputProps';

import FormField from './FormField';

export interface RawFileUploadProps {
    name: string;
    value: File | null;
    onChange: onChangeFunction<File>;
    label?: string;
    required?: boolean;
    disabled?: boolean;
    accept: string;
}

const RawFileUpload: React.FC<RawFileUploadProps> = ({
    name,
    value,
    onChange,
    label = '',
    required = false,
    disabled = false,
    accept,
}) => {
    const extendedValidate = useCallback(() => {
        if (required && value === null) return 'This is a required field.';
    }, [required, value]);

    const [error, showError] = useFieldValidation({
        name,
        required: false,
        value,
        extendedValidate,
    });

    return (
        <FormField name={name} label={label} required={required} error={error}>
            <input
                type="file"
                accept={accept}
                name={name}
                id={name}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disabled}
                data-testid="file-input"
            />
        </FormField>
    );

    function handleChange(e: SyntheticEvent) {
        const files = (e.target as HTMLInputElement).files || [];
        const file = files[0];

        onChange(name, file);
    }

    function handleBlur() {
        showError();
    }
};

export default RawFileUpload;
