import { RootState } from '@reducers/index';
import { CSVUser, User } from 'src/types/shared/User';

export const selectUsers = (state: RootState): Record<number, User> => state.usersReducer.users;
export const selectUser = (state: RootState, id: number): User => state.usersReducer.users[id];
export const selectUsersIsFetching = (state: RootState): boolean => state.usersReducer.isFetching;
export const selectUsersError = (state: RootState): string | null => state.usersReducer.error;

export const selectUsersDeleteError = (state: RootState): string | null =>
    state.usersReducer.deleteError;
export const selectUserIsDeleting = (state: RootState): boolean => state.usersReducer.isDeleting;
export const selectUserDeleteSuccess = (state: RootState): boolean =>
    state.usersReducer.deleteSuccess;

export const selectUsersIsPosting = (state: RootState): boolean => state.usersReducer.isPosting;
export const selectUsersPostError = (state: RootState): string | null =>
    state.usersReducer.postError;
export const selectUsersPostSuccess = (state: RootState): boolean => state.usersReducer.postSuccess;

export const selectUsersFailedUploadUsers = (state: RootState): Record<string, CSVUser> =>
    state.usersReducer.failedUploadUsers;
export const selectUsersFailedUploadCSVString = (state: RootState): string | null =>
    state.usersReducer.failedUploadCSVString;

export const selectCurrentUser = (state: RootState): User | null => state.usersReducer.currentUser;
export const selectIsFetchingCurrentUser = (state: RootState): boolean =>
    state.usersReducer.isFetchingCurrentUser;
