import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getEntityAssessments,
    getEntityAssessmentsFetchError,
    getEntityAssessmentsIsFetching,
} from '@selectors/entityAssessments';
import { fetchEntityAssessments } from '@actions/entityAssessments';

const useFetchEntityAssessments = (entityID: number) => {
    const dispatch = useDispatch();
    const entityAssessments = useSelector(getEntityAssessments);
    const isFetching = useSelector(getEntityAssessmentsIsFetching);
    const fetchError = useSelector(getEntityAssessmentsFetchError);

    useEffect(() => {
        dispatch(fetchEntityAssessments(entityID));
    }, [dispatch, entityID]);

    return { entityAssessments, isFetching, fetchError };
};

export default useFetchEntityAssessments;
