import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import usePrevious from 'lib/src/hooks/usePrevious';
import {
    selectUserDeleteSuccess,
    selectUserIsDeleting,
    selectUsersDeleteError,
} from '@selectors/users';
import { deleteUser } from '@actions/users';

export default function useDeleteUser(id: number, closeModal: () => void) {
    const dispatch = useDispatch();

    const isDeleting = useSelector(selectUserIsDeleting);
    const deleteSuccess = useSelector(selectUserDeleteSuccess);
    const error = useSelector(selectUsersDeleteError);
    const prevDeleteSuccess = usePrevious(deleteSuccess);

    const deleteAUser = useCallback(() => {
        dispatch(deleteUser(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (!prevDeleteSuccess && deleteSuccess) {
            closeModal();
        }
    }, [closeModal, deleteSuccess, prevDeleteSuccess]);

    return {
        error,
        isDeleting,
        deleteSuccess,
        deleteAUser,
    };
}
