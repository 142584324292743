import { TAuthTokenPayload, useOAuth2 } from '@tasoskakour/react-use-oauth2';

import config from '@config/index';

import Meta from 'lib/src/components/meta/Meta';
import { useHistory } from 'react-router-dom';
import ErrorModal from 'lib/src/components/modal/ErrorModal';
import { useEffect, useState } from 'react';
import usePrevious from 'lib/src/hooks/usePrevious';
import { postLogout } from 'lib/src/redux/actions/logout';
import Form from 'lib/src/components/form/Form';

const { OAUTH_URL, OAUTH_CLIENT_ID, OAUTH_REDIRECT_URL, OAUTH_SCOPE, API_URL } = config;

const Login: React.FC = () => {
    const history = useHistory();
    const [showErrorModal, setShowErrorModal] = useState(false);
    const handleSuccess = (payload: DecathlonAuthTokenPayload) => {
        const { access_token, id_token, refresh_token } = payload;
        localStorage.setItem('jwt', access_token);
        localStorage.setItem('idToken', id_token);
        localStorage.setItem('refreshToken', refresh_token);
        setTimeout(() => history.push('/leader-search'));
    };
    const { loading, error, getAuth } = useOAuth2({
        authorizeUrl: OAUTH_URL,
        clientId: OAUTH_CLIENT_ID,
        redirectUri: OAUTH_REDIRECT_URL,
        scope: 'openid profile email',
        responseType: 'code',
        exchangeCodeForTokenQuery: {
            url: `${API_URL}/oauth/token`,
            method: 'POST',
        },
        onSuccess: (payload: DecathlonAuthTokenPayload) => handleSuccess(payload),
        onError: error_ => setShowErrorModal(true),
    });

    const prevError = usePrevious(error);
    useEffect(() => {
        if (error && !prevError) {
            postLogout().finally(() => {
                localStorage.removeItem(`code-${OAUTH_URL}-${OAUTH_CLIENT_ID}-${OAUTH_SCOPE}`);
            });
        }
    }, [error, prevError]);

    return (
        <>
            <Meta title="Login" />

            <div className="login-form-wrapper">
                <div className="login-form flex-column align-center">
                    <h1 className="page-title">Login to the system</h1>
                    <Form
                        onSubmit={getAuth}
                        isPosting={loading}
                        omitCancelButton
                        buttonAlignment="center"
                        submitText="Login"
                    />
                    {!!showErrorModal && (
                        <ErrorModal
                            closeModal={() => setShowErrorModal(false)}
                            title={'Error'}
                            description={JSON.stringify(error) || ''}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

interface DecathlonAuthTokenPayload extends TAuthTokenPayload {
    id_token: string;
}

export default Login;
