import React from 'react';

import { convertEnumToDropdownOption } from 'lib/src/shared/enums/dropdownEnums';
import { entityTypeNames } from 'lib/src/shared/enums/entityEnums';

import useCreateEntity from '../hooks/useCreateEntity';

import Modal from 'lib/src/components/modal/Modal';
import Form from 'lib/src/components/form/Form';
import TextInput from 'lib/src/components/form/TextInput';
import Select from 'lib/src/components/form/Select';

const CreateEntityModal: React.FC<CreateEntityModalProps> = ({ closeModal }) => {
    const { form, handleChange, handleSubmit, isPosting, entitiesOptions } =
        useCreateEntity(closeModal);

    const typeOptions = convertEnumToDropdownOption(entityTypeNames);

    return (
        <Modal title="Create Entity">
            <Form isPosting={isPosting} onSubmit={handleSubmit} onCancel={closeModal}>
                <TextInput
                    name="name"
                    label="Name"
                    value={form.name}
                    onChange={handleChange}
                    required
                />
                <TextInput
                    name="code"
                    label="Code"
                    value={form.code}
                    onChange={handleChange}
                    required
                />
                <Select
                    name="type"
                    label="Type"
                    value={form.type}
                    options={typeOptions}
                    onChange={handleChange}
                    required
                />
                <Select
                    name="parentEntityID"
                    label="Parent Entity"
                    value={form.parentEntityID}
                    options={entitiesOptions}
                    onChange={handleChange}
                    search
                    forceOptionsAbove
                />
            </Form>
        </Modal>
    );
};

interface CreateEntityModalProps {
    closeModal: VoidFunction;
}

export default CreateEntityModal;
