import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { EntitySimple } from 'src/types/shared/Entity';

export const fetchEntitiesSimpleRequest = createAction('fetchEntitiesSimpleRequest');
export const fetchEntitiesSimpleSuccess = createAction<EntitySimple[]>(
    'fetchEntitiesSimpleSuccess',
);
export const fetchEntitiesSimpleFailure = createAction('fetchEntitiesSimpleFailure');

export const fetchEntitiesSimple =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchEntitiesSimpleRequest());
        try {
            const { data } = await api.get<EntitySimple[]>('entities/simple');
            dispatch(fetchEntitiesSimpleSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchEntitiesSimpleFailure, e as APIError);
        }
    };
