import { combineReducers } from 'redux';

import darkModeReducer from 'lib/src/redux/reducers/darkMode';
import entitiesReducer from './entities';
import fieldErrorsReducer from 'lib/src/redux/reducers/fieldErrors';
import redirectsReducer from 'lib/src/redux/reducers/redirects';
import usersReducer from './users';
import assessmentReducer from './assessment';
import userAssessmentReducer from './userAssessment';
import leadersReducer from './leaders';
import topicsBehaviorsReducer from './topicsBehaviors';
import entityAssessmentsReducer from './entityAssessments';
import jobRolesReducer from './jobRoles';

const rootReducer = combineReducers({
    darkModeReducer,
    entitiesReducer,
    fieldErrorsReducer,
    redirectsReducer,
    usersReducer,
    assessmentReducer,
    userAssessmentReducer,
    leadersReducer,
    topicsBehaviorsReducer,
    entityAssessmentsReducer,
    jobRolesReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
