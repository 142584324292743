const ContentBlock: React.FC<ContentBlockProps> = ({ children, extraStyle }) => (
    <div className="content-block" style={extraStyle}>
        {children}
    </div>
);

interface ContentBlockProps {
    children: React.ReactNode;
    extraStyle?: React.CSSProperties;
}

export default ContentBlock;
