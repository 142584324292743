import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from '../../store';
import { APIError } from 'lib/src/types/APIError';

export const deleteUserRequest = createAction('deleteUserRequest');
export const deleteUserSuccess = createAction<number>('deleteUserSuccess');
export const deleteUserFailure = createAction('deleteUserFailure');

export const deleteUser =
    (id: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(deleteUserRequest());

        try {
            await api.delete(`users/${id}`);

            dispatch(deleteUserSuccess(id));
        } catch (e) {
            handleApiErrors(dispatch, deleteUserFailure, e as APIError);
        }
    };
