import React from 'react';
import { useParams } from 'react-router-dom';

import useFetchEntity from './hooks/useFetchEntity';
import useUsersFetch from '@pages/users/users/hooks/useUsersFetch';

import DataCheck from 'lib/src/components/layout/dataCheck/DataCheck';
import EntityContent from './EntityContent';

const Entity: React.FC = () => {
    const { id } = useParams<ParamTypes>();

    const { entity, isFetching, fetchError } = useFetchEntity(+id);
    const { isFetching: isFetchingUsers } = useUsersFetch();

    return (
        <DataCheck
            isEmpty={!entity || entity.id !== +id}
            isLoading={isFetching || isFetchingUsers}
            error={fetchError}
        >
            {entity && <EntityContent entity={entity} />}
        </DataCheck>
    );
};

interface ParamTypes {
    id: string;
}

export default Entity;
