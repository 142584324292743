import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { EntityAssessment } from 'src/types/shared/Entity';

export const fetchEntityAssessmentsRequest = createAction('fetchEntityAssessmentsRequest');
export const fetchEntityAssessmentsSuccess = createAction<EntityAssessment[]>(
    'fetchEntityAssessmentSuccess',
);
export const fetchEntityAssessmentsFailure = createAction('fetchEntityAssessmentsFailure');

export const fetchEntityAssessments =
    (id: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchEntityAssessmentsRequest());
        try {
            const { data } = await api.get<EntityAssessment[]>(`assessment/entity/${id}`);
            dispatch(fetchEntityAssessmentsSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchEntityAssessmentsFailure, e as APIError);
        }
    };
