import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { AssessmentTopic } from 'src/types/shared/Assessment';

import { TopicsBehaviour } from 'src/types/shared/TopicsBehaviors';

const SelfAssessmentResults: React.FC<AssessmentResultsProps> = ({
    id,
    colour = 'var( --other)',
    topicFull,
    collapsible,
    topicsBehaviors,
}) => {
    useEffect(() => {
        ReactTooltip.rebuild();
    }, []);

    return (
        <>
            <div className="flex-column">
                <div className="flex-row" style={{ margin: '10px 0' }}>
                    {topicFull?.percentage ? (
                        <div
                            className={'assessmentResult percentageResult'}
                            style={{ backgroundColor: colour ? colour : undefined, color: 'white' }}
                        >
                            {topicFull.percentage}
                        </div>
                    ) : (
                        <p
                            className={'assessmentResult percentageResult'}
                            style={{ backgroundColor: colour ? colour : undefined, color: 'white' }}
                        >
                            -- %
                        </p>
                    )}

                    {topicFull?.selfVsPreviousDrift ? (
                        <div
                            className={`assessmentResult ${
                                +topicFull.selfVsPreviousDrift < 0
                                    ? 'flex-column-reverse'
                                    : 'flex-column'
                            }`}
                            style={{ color: 'var( --leader-drift)' }}
                            data-tip={`This leader has self-assessed ${Math.abs(
                                +topicFull.selfVsPreviousDrift,
                            )}% ${
                                +topicFull.selfVsPreviousDrift < 0 ? 'lower' : 'higher'
                            } than their previous assessment`}
                        >
                            <div
                                className={`assessment-arrow ${
                                    +topicFull.selfVsPreviousDrift < 0 ? 'down' : 'up'
                                } justify-start`}
                            ></div>
                            {Math.abs(+topicFull.selfVsPreviousDrift)}
                        </div>
                    ) : (
                        <p
                            className="assessment-no-value-sign"
                            style={{ color: 'var( --leader-drift)' }}
                            data-tip={
                                'This leader has self-assessed themselves the same as their previous assessment'
                            }
                        >
                            -- %
                        </p>
                    )}
                    {topicFull?.selfVsPeerDrift ? (
                        <div
                            className={`assessmentResult ${
                                +topicFull.selfVsPeerDrift < 0
                                    ? 'flex-column-reverse'
                                    : 'flex-column'
                            }`}
                            style={{ color: 'var( --peer-drift)' }}
                            data-tip={`This Leader has self-assessed ${Math.abs(
                                +topicFull.selfVsPeerDrift,
                            )}% ${
                                +topicFull.selfVsPeerDrift < 0 ? 'lower' : 'higher'
                            } than their peers have rated them`}
                        >
                            <div
                                className={`assessment-arrow ${
                                    +topicFull.selfVsPeerDrift < 0 ? 'down' : 'up'
                                } justify-start`}
                            ></div>
                            {Math.abs(+topicFull.selfVsPeerDrift)}
                        </div>
                    ) : (
                        <p
                            className="assessment-no-value-sign"
                            style={{ color: 'var( --peer-drift)' }}
                            data-tip={
                                'This leader has self-assessed themselves the same as their peers have rated them'
                            }
                        >
                            -- %
                        </p>
                    )}
                </div>

                {topicFull?.behaviours.map(behaviour => (
                    <div
                        key={behaviour.id}
                        className={`flex-column justify-center ${
                            collapsible !== id && 'collapsible-content'
                        }`}
                        style={{ margin: '10px 0', minHeight: '100px' }}
                    >
                        <div className={'flex-row'}>
                            {behaviour?.percentage ? (
                                <div className={'assessmentResult percentageResult'}>
                                    {behaviour?.percentage}
                                </div>
                            ) : (
                                <p className={'assessmentResult percentageResult'}>-- %</p>
                            )}

                            {behaviour?.selfVsPreviousDrift ? (
                                <div
                                    className={`assessmentResult ${
                                        +behaviour.selfVsPreviousDrift < 0
                                            ? 'flex-column-reverse'
                                            : 'flex-column'
                                    }`}
                                    style={{ color: 'var( --leader-drift)' }}
                                >
                                    <div
                                        className={`assessment-arrow ${
                                            +behaviour.selfVsPreviousDrift < 0 ? 'down' : 'up'
                                        } justify-start`}
                                    ></div>
                                    {Math.abs(+behaviour.selfVsPreviousDrift)}
                                </div>
                            ) : (
                                <p
                                    style={{ color: 'var( --leader-drift)' }}
                                    className="assessment-no-value-sign"
                                >
                                    -- %
                                </p>
                            )}

                            {behaviour?.selfVsPeerDrift ? (
                                <div
                                    className={`assessmentResult ${
                                        +behaviour.selfVsPeerDrift < 0
                                            ? 'flex-column-reverse'
                                            : 'flex-column'
                                    }`}
                                    style={{ color: 'var( --peer-drift)' }}
                                >
                                    <div
                                        className={`assessment-arrow ${
                                            +behaviour.selfVsPeerDrift < 0 ? 'down' : 'up'
                                        } justify-start`}
                                    ></div>
                                    {Math.abs(+behaviour.selfVsPeerDrift)}
                                </div>
                            ) : (
                                <p
                                    style={{ color: 'var( --peer-drift)' }}
                                    className="assessment-no-value-sign"
                                >
                                    -- %
                                </p>
                            )}
                        </div>
                        <p style={{ textAlign: 'center' }}>
                            {
                                topicsBehaviors.behaviours.find(item => item.id === behaviour.id)
                                    ?.name
                            }
                        </p>
                    </div>
                ))}
            </div>
        </>
    );
};

interface AssessmentResultsProps {
    colour: string | null;
    topicFull: AssessmentTopic | undefined;
    collapsible?: number | null;
    id: number;
    topicsBehaviors: TopicsBehaviour;
}

export default SelfAssessmentResults;
