import { Route, Switch, useRouteMatch } from 'react-router-dom';

import DefaultRedirect from './DefaultRedirect';
import JobRoleSearch from '@pages/jobRoleSearch/search/JobRoleSearch';
import JobRolesComparison from '@pages/jobRoleSearch/comparison/JobRolesComparison';

const JobRoleSearchRoutes: React.FC = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`}>
                <JobRoleSearch />
            </Route>
            <Route exact path={`${path}/compare`}>
                <JobRolesComparison />
            </Route>
            <DefaultRedirect to={path} />
        </Switch>
    );
};

export default JobRoleSearchRoutes;
