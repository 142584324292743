import React from 'react';

import { Entity } from 'src/types/shared/Entity';
import { User } from 'src/types/shared/User';

import Table from 'lib/src/components/table/Table';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import LinkButton from 'lib/src/components/button/LinkButton';

const EntityHRStaff: React.FC<EntityHRStaffProps> = ({ entity, isAdmin }) => {
    const columns = [
        {
            key: 1,
            heading: 'UUID',
            getValue: (row: User) => row.uuid,
        },
        {
            key: 2,
            heading: 'First name',
            getValue: (row: User) => row.firstName,
        },
        {
            key: 3,
            heading: 'Last name',
            getValue: (row: User) => row.lastName,
        },
        {
            key: 4,
            heading: '',
            getValue: (row: User) =>
                isAdmin ? (
                    <ButtonRow alignment="right">
                        <LinkButton href={`/users/${row.id}`}>View</LinkButton>
                    </ButtonRow>
                ) : null,
        },
    ];

    return <Table columns={columns} rows={entity.hrs} />;
};

interface EntityHRStaffProps {
    entity: Entity;
    isAdmin: boolean;
}

export default EntityHRStaff;
