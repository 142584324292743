import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from '../../store';
import { APIError } from 'lib/src/types/APIError';
import { CSVUser } from 'src/types/shared/User';

export const postUploadUsersCSVRequest = createAction('postUploadUsersCSVRequest');
export const postUploadUsersCSVSuccess = createAction<PostUploadCSVData>(
    'postUploadUsersCSVSuccess',
);
export const postUploadUsersCSVFailure = createAction('postUploadUsersCSVFailure');

export interface PostUploadCSVData {
    failedCsvString?: '';
    failedCsvData: CSVUser[];
}

export interface PostUploadCSVResponse {
    data: PostUploadCSVData;
}

export const postUploadUsersCSV =
    (file: File) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(postUploadUsersCSVRequest());
        try {
            const formData = new FormData();
            formData.append('file', file, file.name);

            const { data }: PostUploadCSVResponse = await api.postMedia('users/import', formData);
            dispatch(postUploadUsersCSVSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, postUploadUsersCSVFailure, e as APIError);
        }
    };
