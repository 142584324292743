import { useEffect, useState } from 'react';

import { Entity } from 'src/types/shared/Entity';

const useGetEntitiesAndNested = (entities: Entity[]) => {
    const [nestedEntities, setNestedEntities] = useState<Entity[]>(entities);

    const getNestedEntities = (entities: Entity[]) => {
        for (let i = 0; i < entities.length; i++) {
            if (entities[i].children.length) {
                setNestedEntities(prevState => [...prevState, ...entities[i].children]);
                getNestedEntities(entities[i].children);
            }
        }
    }; // eslint-disable-line

    useEffect(() => {
        setNestedEntities([]);
        getNestedEntities(entities);
    }, []); // eslint-disable-line

    return entities.concat(nestedEntities);
};

export default useGetEntitiesAndNested;
