import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getEntitiesSimple } from '@selectors/entities';
import { Entity } from 'src/types/shared/Entity';

const useEntityNameBuilder = (entity: Entity) => {
    const entitiesSimple = useSelector(getEntitiesSimple);

    const name = useMemo(() => {
        let fullName = entity.name;
        let parentEntityID = entity.parentEntityID;

        while (parentEntityID) {
            const parentEntity = entitiesSimple[parentEntityID];

            if (!parentEntity) {
                parentEntityID = null;
                break;
            }

            fullName = `${parentEntity.name} / ${fullName}`;
            parentEntityID = parentEntity.parentEntityID;
        }

        return fullName;
    }, [entity, entitiesSimple]);

    return name;
};

export default useEntityNameBuilder;
