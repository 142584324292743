import { useEffect, useRef, useState } from 'react';
import useWindowDimensions from './useWindowDimensions';

const useElementOverflow = (isOpen: boolean) => {
    const elementRef = useRef<HTMLDivElement>(null);
    const { height } = useWindowDimensions();

    const [isElementOverflowing, setIsElementOverflowing] = useState(false);

    useEffect(() => {
        if (elementRef.current) {
            const rect = elementRef.current.getBoundingClientRect();

            if (rect.bottom + rect.height > height) {
                setIsElementOverflowing(true);
            } else {
                setIsElementOverflowing(false);
            }
        }

        return () => {
            setIsElementOverflowing(false);
        };
    }, [elementRef, height, isOpen]);

    return { elementRef, isElementOverflowing };
};

export default useElementOverflow;
