import { Route, Switch } from 'react-router-dom';

import AuthRoutes from './AuthRoutes';
import SubRouter from './SubRouter';
import UsersRoutes from './UsersRoutes';
import DefaultRedirect from './DefaultRedirect';
import EntitiesRoutes from './EntitiesRoutes';
import LeadersSearchRoutes from './LeadersSearchRoutes';
import AuthLoading from '@pages/auth/AuthLoading';
import JobRoleSearchRoutes from './JobRoleSearchRoutes';

const Routes: React.FC = () => (
    <Switch>
        <Route exact path="/">
            <AuthLoading />
        </Route>
        <SubRouter path="/auth">
            <AuthRoutes />
        </SubRouter>
        <SubRouter path="/leader-search">
            <LeadersSearchRoutes />
        </SubRouter>
        <SubRouter path="/job-role-search">
            <JobRoleSearchRoutes />
        </SubRouter>
        <SubRouter path="/users">
            <UsersRoutes />
        </SubRouter>
        <SubRouter path="/entities">
            <EntitiesRoutes />
        </SubRouter>
        <DefaultRedirect to="/users" />
    </Switch>
);

export default Routes;
