import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { Entity } from 'src/types/shared/Entity';

export const fetchEntityRequest = createAction('fetchEntityRequest');
export const fetchEntitySuccess = createAction<Entity>('fetchEntitySuccess');
export const fetchEntityFailure = createAction('fetchEntityFailure');

export const fetchEntity =
    (id: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchEntityRequest());
        try {
            const { data } = await api.get<Entity>(`entities/${id}`);
            dispatch(fetchEntitySuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchEntityFailure, e as APIError);
        }
    };
