import { useState } from 'react';
import { useSelector } from 'react-redux';

import useUsersFetch from './hooks/useUsersFetch';
import { selectUsers, selectUsersIsFetching } from '@selectors/users';
import UsersTable from './UsersTable';
import CreateHeader from '@components/layout/createHeader/CreateHeader';
import Title from 'lib/src/components/typography/Title';
import ActionButton from 'lib/src/components/button/ActionButton';
import UploadUsersCSVModal from './modals/UploadUsersCSVModal';
import UploadFailuresModal from './modals/UploadFailuresModal';
import Meta from 'lib/src/components/meta/Meta';
import UploadUsersSuccessModal from './modals/UploadUsersSuccessModal';

const Users: React.FC = () => {
    useUsersFetch();
    const users = useSelector(selectUsers);
    const isFetching = useSelector(selectUsersIsFetching);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showUploadFailuresModal, setShowUploadFailuresModal] = useState<boolean>(false);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

    return (
        <>
            <Meta title="Users" isAdminArea />
            <CreateHeader>
                <Title>Users</Title>
                <ActionButton
                    source="positive"
                    icon="upload"
                    onClick={() => setShowUploadModal(true)}
                >
                    Import Users
                </ActionButton>
            </CreateHeader>

            <UsersTable users={users} isFetching={isFetching} />

            {showUploadModal && (
                <UploadUsersCSVModal
                    closeModal={() => setShowUploadModal(false)}
                    showUploadFailuresModal={() => setShowUploadFailuresModal(true)}
                    showSuccessModal={() => setShowSuccessModal(true)}
                />
            )}
            {showUploadFailuresModal && (
                <UploadFailuresModal closeModal={() => setShowUploadFailuresModal(false)} />
            )}
            {showSuccessModal && (
                <UploadUsersSuccessModal closeModal={() => setShowSuccessModal(false)} />
            )}
        </>
    );
};

export default Users;
