import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { ComparisonParams, ComparisonResponse } from 'src/types/shared/Comparison';

export const compareLeadersRequest = createAction('compareLeadersRequest');
export const compareLeadersSuccess = createAction<ComparisonResponse>('compareLeadersSuccess');
export const compareLeadersFailure = createAction('compareLeadersFailure');

export const compareLeaders =
    (params: ComparisonParams) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(compareLeadersRequest());
        try {
            const { data }: CreateSearchLeadersResponse = await api.get(
                'users/assessment-results',
                params,
            );
            dispatch(compareLeadersSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, compareLeadersFailure, e as APIError);
        }
    };

interface CreateSearchLeadersResponse {
    data: ComparisonResponse;
}
