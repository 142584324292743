import { RootState } from '@reducers/index';
import { AnchorResult, ComparisonResult } from 'src/types/shared/Comparison';
import { JobRole } from 'src/types/shared/JobRole';

export const getJobRoles = (state: RootState): Record<number, JobRole> =>
    state.jobRolesReducer.jobRoles;
export const getJobRolesSearchIsPosting = (state: RootState): boolean =>
    state.jobRolesReducer.isPosting;
export const getJobRolesSearchSearchError = (state: RootState): string | null =>
    state.jobRolesReducer.postError;

export const getAnchorJobRole = (state: RootState): AnchorResult | null =>
    state.jobRolesReducer.anchorResult;
export const getComparisonJobRoles = (state: RootState): ComparisonResult[] | null =>
    state.jobRolesReducer.comparisonResults;
export const getJobRolesComparisonIsFetching = (state: RootState): boolean =>
    state.jobRolesReducer.isFetchingComparison;
export const getJobRolesComparisonError = (state: RootState): string | null =>
    state.jobRolesReducer.fetchComparisonError;
