import React from 'react';

import useLeadersSelection from './hooks/useLeadersSelection';
import useLeadersSearch from './hooks/useLeadersSearch';

import CreateHeader from '@components/layout/createHeader/CreateHeader';
import Title from 'lib/src/components/typography/Title';
import LeadersTable from './LeadersTable';
import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import Checkbox from 'lib/src/components/form/Checkbox';
import DatePicker from 'lib/src/components/form/DatePicker';
import Message from 'lib/src/components/typography/Message';
import TextInput from 'lib/src/components/form/TextInput';
import NumberInput from 'lib/src/components/form/NumberInput';
import ActionButton from 'lib/src/components/button/ActionButton';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import Meta from 'lib/src/components/meta/Meta';
import LinkButton from 'lib/src/components/button/LinkButton';
import Form from 'lib/src/components/form/Form';

const LeadersSearch: React.FC = () => {
    const {
        isPosting,
        leaders,
        error,
        formState,
        handleChange,
        handleSubmit,
        tableWasSearched,
        handleDownloadCSV,
    } = useLeadersSearch();

    const {
        selected,
        selectedLeader,
        isSelected,
        isSelectedLeader,
        updateSelection,
        isMaxedOut,
        comparisonLink,
    } = useLeadersSelection();

    return (
        <>
            <Meta title="Leader Search" />

            <CreateHeader>
                <Title>Leader Search</Title>
            </CreateHeader>

            <Form onSubmit={handleSubmit} omitButtons>
                <ContentBlock>
                    <div className="flex-row justify-around">
                        <div className="flex-column flex-5" style={{ paddingRight: '15px' }}>
                            <div className="dual-fields">
                                <div className="field-item">
                                    <TextInput
                                        name="firstName"
                                        value={formState.firstName}
                                        onChange={handleChange}
                                        label="First name"
                                    />
                                </div>

                                <div className="field-item">
                                    <TextInput
                                        name="lastName"
                                        value={formState.lastName}
                                        onChange={handleChange}
                                        label="Last name"
                                    />
                                </div>
                            </div>
                            <Checkbox
                                name="includeSubEntities"
                                value={formState.includeSubEntities}
                                onChange={handleChange}
                                label="Include Sub-Entities?"
                            />
                            <div className="flex-column">
                                <Message extraStyle={{ marginBottom: '10px' }}>
                                    Latest Assessment
                                </Message>
                                <DatePicker
                                    label="From"
                                    name="latestAssessmentDateFrom"
                                    value={formState.latestAssessmentDateFrom}
                                    onChange={handleChange}
                                />
                                <DatePicker
                                    label="To"
                                    name="latestAssessmentDateTo"
                                    value={formState.latestAssessmentDateTo}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="flex-column flex-5">
                            <TextInput
                                name="entityLink"
                                value={formState.entityLink}
                                onChange={handleChange}
                                label="Entity Link"
                                placeholder="North West"
                                icon="search"
                                // extraClasses="no-padding"
                            />

                            <div className="flex-column">
                                <div className="flex-column">
                                    <Message extraStyle={{ marginBottom: '10px' }}>
                                        Self Score
                                    </Message>
                                    <div className="flex-row">
                                        <NumberInput
                                            name="selfScoreFrom"
                                            value={
                                                formState.selfScoreFrom === null
                                                    ? 0
                                                    : formState.selfScoreFrom
                                            }
                                            onChange={handleChange}
                                            label="From"
                                        />

                                        <div style={{ width: '30px' }}></div>
                                        <NumberInput
                                            name="selfScoreTo"
                                            value={
                                                formState.selfScoreTo === null
                                                    ? 0
                                                    : formState.selfScoreTo
                                            }
                                            onChange={handleChange}
                                            label="To"
                                        />
                                    </div>
                                </div>

                                <div className="flex-column">
                                    <Message extraStyle={{ marginBottom: '10px' }}>
                                        Peer Score
                                    </Message>
                                    <div className="flex-row">
                                        <NumberInput
                                            name="peerScoreFrom"
                                            value={
                                                formState.peerScoreFrom === null
                                                    ? 0
                                                    : formState.peerScoreFrom
                                            }
                                            onChange={handleChange}
                                            label="From"
                                        />
                                        <div style={{ width: '30px' }}></div>
                                        <NumberInput
                                            name="peerScoreTo"
                                            value={
                                                formState.peerScoreTo === null
                                                    ? 0
                                                    : formState.peerScoreTo
                                            }
                                            onChange={handleChange}
                                            label="To"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="flex-1 flex-column justify-end"
                            style={{ marginBottom: '20px' }}
                        >
                            <ButtonRow>
                                <ActionButton
                                    type="submit"
                                    source="positive"
                                    isPosting={isPosting}
                                    disabled={isPosting}
                                >
                                    Search
                                </ActionButton>
                                <ActionButton onClick={handleDownloadCSV} type="button">
                                    Export to CSV
                                </ActionButton>
                            </ButtonRow>
                        </div>
                    </div>
                </ContentBlock>
            </Form>

            {(!!selected.length || selectedLeader) && (
                <>
                    <CreateHeader extraClasses="top-margin">
                        <Title>Comparison</Title>
                        {selected.length > 1 && (
                            <LinkButton href={comparisonLink} openInNewTab>
                                Compare
                            </LinkButton>
                        )}
                    </CreateHeader>

                    <ContentBlock>
                        {!!selected.length && (
                            <>
                                <Title>Selected Users</Title>
                                <ButtonRow alignment="left">
                                    {selected.map(leader => (
                                        <ActionButton
                                            key={leader.id}
                                            source="negative"
                                            icon="times"
                                            onClick={() => updateSelection(leader, false)}
                                        >
                                            {leader.firstName} {leader.lastName}
                                        </ActionButton>
                                    ))}
                                </ButtonRow>
                            </>
                        )}

                        {!!selected.length && selectedLeader && <br />}

                        {selectedLeader && (
                            <>
                                <Title>Selected Leader</Title>
                                <ButtonRow alignment="left">
                                    <ActionButton
                                        key={selectedLeader.id}
                                        source="negative"
                                        icon="times"
                                        onClick={() => updateSelection(selectedLeader, true)}
                                    >
                                        {selectedLeader.firstName} {selectedLeader.lastName}
                                    </ActionButton>
                                </ButtonRow>
                            </>
                        )}
                    </ContentBlock>
                </>
            )}

            {tableWasSearched && (
                <>
                    <CreateHeader extraClasses="top-margin">
                        <Title>Results</Title>
                    </CreateHeader>
                    <LeadersTable
                        leaders={leaders}
                        isFetching={isPosting}
                        error={error}
                        isSelected={isSelected}
                        isSelectedLeader={isSelectedLeader}
                        updateSelection={updateSelection}
                        isMaxedOut={isMaxedOut}
                    />
                </>
            )}
        </>
    );
};

export default LeadersSearch;
