import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import NavItem from './NavItem';
import { postLogout } from 'lib/src/redux/actions/logout';

import { selectCurrentUser } from '@selectors/users';
import { fetchCurrentUser } from '@actions/users/fetchCurrentUser';
import { useEffect } from 'react';
import useIsLoggedIn from 'lib/src/hooks/useIsLoggedIn';
import { userRoles } from 'lib/src/shared/enums/userEnums';

const Nav: React.FC = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const path = location.pathname.toLowerCase();
    const curUser = useSelector(selectCurrentUser);
    const { isLoggedIn } = useIsLoggedIn();
    useEffect(() => {
        if (isLoggedIn) {
            dispatch(fetchCurrentUser());
        }
    }, [isLoggedIn, dispatch]);
    if (path.startsWith('/auth')) return null;

    return (
        <nav className="navigation custom-scroll">
            <ul className="nav-list">
                <NavItem to="/leader-search" text="Leader Search" icon="user-magnifying-glass" />
                <NavItem
                    to="/job-role-search"
                    text="Job Role Search"
                    icon="building-magnifying-glass"
                />
                {curUser?.role === userRoles.administrator && (
                    <NavItem to="/users" text="Users" icon="users" />
                )}
                <NavItem to="/entities" text="Hierarchy" icon="sitemap" />
                <br />
                <NavItem
                    to="/auth/login"
                    text="Logout"
                    icon="sign-out"
                    onClick={async () => {
                        await postLogout();
                        window.location.reload();
                    }}
                />
            </ul>
        </nav>
    );
};

export default Nav;
