import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { AllTypesOfAssessment } from 'src/types/shared/Assessment';

export const fetchAssessmentRequest = createAction('fetchAssessmentRequest');
export const fetchAssessmentSuccess = createAction<AllTypesOfAssessment>('fetchAssessmentSuccess');
export const fetchAssessmentFailure = createAction('fetchAssessmentFailure');

export const fetchAssessment =
    (id: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchAssessmentRequest());
        try {
            const { data } = await api.get<AllTypesOfAssessment>(`assessment/results/${id}`);
            dispatch(fetchAssessmentSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchAssessmentFailure, e as APIError);
        }
    };
