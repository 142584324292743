import React, { useState } from 'react';
import dayjs from 'dayjs';

import { PeerResult } from 'src/types/shared/Assessment';
import Table from 'lib/src/components/table/Table';
import { TopicsBehaviour } from 'src/types/shared/TopicsBehaviors';
import PeerAssessmentResults from './PeerAssessmentResults';

import { DATE_FORMAT } from 'lib/src/constants/dateFormats';

const PeerAssessments: React.FC<PeerAssessmentsProps> = ({ peerResults, topicsBehaviors }) => {
    const [collapsible, setCollapsible] = useState<number | null>(null);

    const result = peerResults.map((item, index) => ({ ...item, id: index }));

    const columns = [
        {
            key: 1,
            heading: 'Completed On',
            getValue: (row: PeerResultWithId) => (
                <div className="flex-row">
                    {dayjs(row.createdOn).format(DATE_FORMAT)}
                    <button
                        type="button"
                        className="collapsible"
                        onClick={() => {
                            if (row.id === collapsible) {
                                setCollapsible(null);
                            } else {
                                setCollapsible(row?.id);
                            }
                        }}
                    >
                        <i className={`fa fa-caret-down ${collapsible === row.id && 'down'}`}></i>
                    </button>
                </div>
            ),
        },
    ];

    topicsBehaviors.topics.forEach((topic, index) => {
        const obj = {
            key: index + 2,
            heading: topic.name,
            getValue: (row: PeerResultWithId) => {
                return (
                    <PeerAssessmentResults
                        id={row.id}
                        topicFull={row.topics.find(t => t.id === topic.id)}
                        colour={topic.colour}
                        collapsible={collapsible}
                        topicsBehaviors={topicsBehaviors}
                    />
                );
            },
        };

        columns.push(obj);
    });

    return <Table columns={columns} rows={result} />;
};

interface PeerAssessmentsProps {
    peerResults: PeerResult[];
    topicsBehaviors: TopicsBehaviour;
}
interface PeerResultWithId extends PeerResult {
    id: number;
}

export default PeerAssessments;
