export const chartRotationValues = [
    0, -22, -45, -67, -90, -112, -135, -158, 180, 158, 135, 112, 90, 67, 45, 22,
];

export const DATA_POINT_COLOURS = {
    ZONE_OF_EXCELLENCE: '#C9E136',
    SECOND: '#FF8946',
    THIRD: '#1CEADD',
    FOURTH: '#FF62D3',
    FIFTH: '#B66CFF',
};
