import { useMemo, useState } from 'react';
import qs from 'qs';

import { Leader } from 'src/types/shared/LeaderSearch';

const useLeadersSelection = () => {
    const [selected, setSelected] = useState<Leader[]>([]);
    const [selectedLeader, setSelectedLeader] = useState<Leader | null>(null);

    const isSelected = (leaderID: number) => {
        if (selected.map(curLeader => curLeader.id).includes(leaderID)) {
            return true;
        }

        return false;
    };

    const isSelectedLeader = (leaderID: number) => {
        if (selectedLeader?.id === leaderID) {
            return true;
        }

        return false;
    };

    const updateSelection = (leader: Leader, isLeader = false) => {
        if (isLeader) {
            const isLeaderSelected = isSelectedLeader(leader.id);

            if (isLeaderSelected) {
                setSelectedLeader(null);
            } else {
                setSelectedLeader(leader);
            }
        } else {
            const isLeaderSelected = isSelected(leader.id);

            if (isLeaderSelected) {
                const newSelectedValue = selected.filter(item => item.id !== leader.id);
                setSelected(newSelectedValue);
            } else {
                const newSelectedValue = [...selected, leader];
                setSelected(newSelectedValue);
            }
        }
    };

    const isMaxedOut = selected.length > 3;

    const comparisonLink = useMemo((): string => {
        const link = '/leader-search/compare';
        const paramsData: ParamsData = {
            ids: selected.map(leader => leader.id + ''),
        };

        if (selectedLeader) {
            paramsData.leaderID = selectedLeader.id + '';
        }

        const params = qs.stringify(paramsData, { arrayFormat: 'repeat' });
        return `${link}?${params}`;
    }, [selected, selectedLeader]);

    return {
        selected,
        selectedLeader,
        isSelected,
        isSelectedLeader,
        updateSelection,
        isMaxedOut,
        comparisonLink,
    };
};

interface ParamsData {
    ids: string[];
    leaderID?: string;
}

export default useLeadersSelection;
