import React from 'react';
import { Helmet } from 'react-helmet';

const Meta: React.FC<MetaProps> = ({ title, isAdminArea = false }) => (
    <Helmet>
        <title>
            {title} | Decathlon 5.0 Leader{isAdminArea ? ' Admin Portal' : ''}
        </title>
    </Helmet>
);

interface MetaProps {
    title: string;
    isAdminArea?: boolean;
}

export default Meta;
