import { useMemo } from 'react';

import { ChartStat, ChartStats, ChartTopic } from 'lib/src/components/charts/AssessmentChart';
import { AnchorResult } from 'src/types/shared/Comparison';

const useTopicStatsForCompareChart = (
    topicsForAssessmentChart: ChartTopic[],
    anchorResults: AnchorResult,
    leaderResults: AnchorResult | null,
) => {
    const topicStatsForCompareChart: ChartStats[] = useMemo(
        () =>
            [...topicsForAssessmentChart]
                .sort((a, b) => a.sort - b.sort)
                .map(topic => {
                    const relatedAnchorTopic = anchorResults.topicResults.find(
                        t => t.id === topic.id,
                    );

                    const anchorPercentage = relatedAnchorTopic?.percentage || 0;

                    const relatedLeaderTopic = leaderResults?.topicResults.find(
                        t => t.id === topic.id,
                    );

                    const leaderPercentage = relatedLeaderTopic?.percentage || 0;

                    const stats: ChartStat[] = [
                        {
                            name: 'Self score',
                            percentage: +anchorPercentage.toFixed(0),
                        },
                    ];

                    if (leaderResults) {
                        stats.unshift({
                            name: 'Leader score',
                            percentage: +leaderPercentage.toFixed(0),
                        });
                    }

                    return {
                        stats,
                    };
                }),
        [topicsForAssessmentChart, anchorResults.topicResults, leaderResults],
    );

    return topicStatsForCompareChart;
};

export default useTopicStatsForCompareChart;
