import { jwtDecode } from 'jwt-decode';

interface JWT {
    nbf: number;
    exp: number;
}

interface IDToken {
    sub: string;
    aud: string;
    jti: string;
    iss: string;
    iat: number;
    exp: number;
    auth_time: number;
    entryUUID: string;
    role: string[];
    c: string;
    mail: string;
    sitepartynumber: string;
    displayName: string;
    givenName: string;
    cn: string;
    sitetype: string;
    title: string;
    objectClass: string[];
    uuid: string;
    allsites: string;
    uid: string;
    site: string;
    federation_idp: string;
    familyName: string;
    sitename: string;
    sn: string;
    partyNumber: string;
    costcenter: string;
    jobname: string;
    ['pi.sri']: string;
}

export function validateJWT(token: string): boolean {
    try {
        if (!token) return false;
        return !!token;
    } catch {
        return false;
    }
}

export const getJwtToken = (): string | null => {
    const token = localStorage.getItem('jwt');
    return token;
};

export const getIDToken = (): string | null => {
    const token = localStorage.getItem('idToken');
    return token;
};

export const clearJwtAndRefreshToken = (): void => {
    localStorage.removeItem('jwt');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('idToken');
};

export const getDecodedJwtToken = (): JWT | null => {
    const jwtToken = getJwtToken();

    if (jwtToken === null) return null;

    const isValid = validateJWT(jwtToken);

    if (!isValid) {
        return null;
    }

    const decoded = jwtDecode<JWT>(jwtToken);
    return decoded;
};

export const getDecodedIDToken = (): IDToken | null => {
    const idToken = getIDToken();

    if (!idToken) return null;

    try {
        const decoded = jwtDecode<IDToken>(idToken);
        return decoded;
    } catch {
        return null;
    }
};
