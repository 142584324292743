import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Entity } from 'src/types/shared/Entity';

import { getEntitiesPostError } from '@selectors/entities';

import usePrevious from 'lib/src/hooks/usePrevious';
import useEntityNameBuilder from './hooks/useEntityNameBuilder';

import CreateHeader from '@components/layout/createHeader/CreateHeader';
import ActionButton from 'lib/src/components/button/ActionButton';
import Title from 'lib/src/components/typography/Title';
import FlexWrapper from 'lib/src/components/layout/wrapper/FlexWrapper';
import EntityConfiguration from './EntityConfiguration';
import EntityEditHistory from './EntityEditHistory';
import EntityUsers from './EntityUsers';
import CreateChildEntityModal from './modals/CreateChildEntityModal';
import ErrorModal from 'lib/src/components/modal/ErrorModal';
import Meta from 'lib/src/components/meta/Meta';
import { selectCurrentUser } from '@selectors/users';
import { userRoles } from 'lib/src/shared/enums/userEnums';

const EntityContent: React.FC<EntityContentProps> = ({ entity }) => {
    const [showCreateChildEntityModal, setShowCreateChildEntityModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const postError = useSelector(getEntitiesPostError);
    const prevPostError = usePrevious(postError);
    const curUser = useSelector(selectCurrentUser);

    const pageName = useEntityNameBuilder(entity);

    useEffect(() => {
        if (postError && !prevPostError) setShowErrorModal(true);
    }, [postError, prevPostError]);

    return (
        <>
            <Meta title={entity.name} />

            <CreateHeader>
                <Title>{pageName}</Title>
                {curUser?.role === userRoles.administrator && (
                    <ActionButton
                        source="positive"
                        icon="plus"
                        onClick={() => setShowCreateChildEntityModal(true)}
                    >
                        Create Child Entity
                    </ActionButton>
                )}
            </CreateHeader>

            <FlexWrapper justify="between" gap={30}>
                <FlexWrapper width={4} direction="column" gap={10}>
                    <EntityConfiguration entity={entity} />
                    <EntityEditHistory entity={entity} />
                </FlexWrapper>

                <FlexWrapper width={8}>
                    <EntityUsers entity={entity} />
                </FlexWrapper>
            </FlexWrapper>

            {showCreateChildEntityModal && (
                <CreateChildEntityModal
                    entity={entity}
                    closeModal={() => setShowCreateChildEntityModal(false)}
                />
            )}
            {showErrorModal && (
                <ErrorModal
                    description={postError ? postError : undefined}
                    closeModal={() => setShowErrorModal(false)}
                />
            )}
        </>
    );
};

interface EntityContentProps {
    entity: Entity;
}

export default EntityContent;
