import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { convertArrToObj } from 'lib/src/utils/generic';
import {
    fetchAllUsersFailure,
    fetchAllUsersRequest,
    fetchAllUsersSuccess,
    fetchSingleUserFailure,
    fetchSingleUserRequest,
    fetchSingleUserSuccess,
    postUploadUsersCSVRequest,
    postUploadUsersCSVFailure,
    postUploadUsersCSVSuccess,
    PostUploadCSVData,
} from '@actions/users';
import { CSVUser, User } from 'src/types/shared/User';
import { deleteUserFailure, deleteUserRequest, deleteUserSuccess } from '@actions/users/deleteUser';
import {
    fetchCurrentUserFailure,
    fetchCurrentUserRequest,
    fetchCurrentUserSuccess,
} from '@actions/users/fetchCurrentUser';

interface UsersState {
    isFetching: boolean;
    isPosting: boolean;
    isDeleting: boolean;
    postSuccess: boolean;
    deleteSuccess: boolean;
    postError: string | null;
    error: string | null;
    deleteError: string | null;
    users: { [key: number]: User };
    failedUploadUsers: Record<string, CSVUser>;
    failedUploadCSVString: string | null;
    isFetchingCurrentUser: boolean;
    currentUser: User | null;
}

const initialState: UsersState = {
    isFetching: false,
    isPosting: false,
    isDeleting: false,
    postSuccess: false,
    deleteSuccess: false,
    postError: null,
    deleteError: null,
    error: null,
    users: {},
    failedUploadUsers: {},
    failedUploadCSVString: null,
    isFetchingCurrentUser: false,
    currentUser: null,
};

export default createReducer(initialState, {
    [fetchAllUsersRequest.type]: handleFetchRequest,
    [fetchAllUsersSuccess.type]: handleFetchUsersSuccess,
    [fetchAllUsersFailure.type]: handleFetchError,
    [fetchSingleUserRequest.type]: handleFetchRequest,
    [fetchSingleUserSuccess.type]: handleFetchSingleUserSuccess,
    [fetchSingleUserFailure.type]: handleFetchError,
    [postUploadUsersCSVRequest.type]: handleUploadRequest,
    [postUploadUsersCSVSuccess.type]: handleUploadSuccess,
    [postUploadUsersCSVFailure.type]: handlePostError,
    [deleteUserRequest.type]: handleDeleteRequest,
    [deleteUserFailure.type]: handleDeleteError,
    [deleteUserSuccess.type]: handleDeleteSuccess,
    [fetchCurrentUserRequest.type]: handleFetchCurrentUserRequest,
    [fetchCurrentUserSuccess.type]: handleFetchCurrentUserSuccess,
    [fetchCurrentUserFailure.type]: handleFetchCurrentUserFailure,
});

function handleFetchCurrentUserRequest(state: UsersState) {
    state.isFetchingCurrentUser = true;
}

function handleFetchCurrentUserSuccess(state: UsersState, action: PayloadAction<User>) {
    state.currentUser = action.payload;
    state.isFetchingCurrentUser = false;
}
function handleFetchCurrentUserFailure(state: UsersState) {
    state.isFetchingCurrentUser = false;
}

function handleFetchRequest(state: UsersState) {
    state.isFetching = true;
}

function handleFetchError(state: UsersState, action: PayloadAction<string>) {
    state.error = action.payload;
    state.isFetching = false;
}

function handleFetchUsersSuccess(state: UsersState, action: PayloadAction<User[]>) {
    state.users = convertArrToObj(action.payload);
    state.isFetching = false;
}

function handleFetchSingleUserSuccess(state: UsersState, action: PayloadAction<User>) {
    state.users[action.payload.id] = action.payload;
    state.isFetching = false;
}

// function handlePostRequest(state: UsersState) {
//     state.isPosting = true;
//     state.postSuccess = false;
// }

function handleUploadRequest(state: UsersState) {
    state.failedUploadUsers = {};
    state.failedUploadCSVString = null;
    state.isPosting = true;
    state.postSuccess = false;
}

function handleUploadSuccess(state: UsersState, action: PayloadAction<PostUploadCSVData>) {
    const { failedCsvData, failedCsvString } = action.payload;
    state.isPosting = false;
    state.postSuccess = true;
    state.failedUploadUsers = convertArrToObj(failedCsvData, 'UUID');
    state.failedUploadCSVString = failedCsvString || null;
}

function handlePostError(state: UsersState, action: PayloadAction<string>) {
    state.postError = action.payload;
    state.isPosting = false;
}

function handleDeleteRequest(state: UsersState) {
    state.isDeleting = true;
    state.deleteSuccess = false;
    state.deleteError = null;
}

function handleDeleteError(state: UsersState, action: PayloadAction<string>) {
    state.deleteError = action.payload;
    state.isDeleting = false;
}

function handleDeleteSuccess(state: UsersState, action: PayloadAction<number>) {
    state.isDeleting = false;
    state.deleteSuccess = true;
    delete state.users[action.payload];
}
